<template>
    <div class="container">
        <div class="l-page-content" :class="{'loading-big': loading.data}">
            <h1 class="l-page-title" style="max-width: 880px">
                {{ article.name }}
            </h1>
            <template v-if="article && article.content_type === 'video'">
                <div class="youtubeVideo mb-5">
                    <iframe width="560" height="315" :src="'https://www.youtube.com/embed/' + $utils.getYoutubeId(article.attributes.video)" frameborder="0" allowfullscreen id="productVideo"></iframe>
                </div>
            </template>
            <template v-else>
                <XImgset class="l-article__img" v-if="article.image" :src="article.image" :width="844" :alt="article.title"/>
                <div class="c-article-card__author-info mb-4" v-if="article.id">
                    <div class="c-article-card__author-name" v-if="article.user">
                        {{ article.user.name }}
                    </div>
                    <div class="c-article-card__author-stats">
                        <div>{{ article.date | moment('MMM D, YYYY') }}</div>
                        <div v-if="article.attributes?.read_duration">
                            <span class="dot"></span>
                            <ClockIcon height="24"/>
                            {{ article.attributes?.read_duration }} Min Read
                        </div>
                    </div>
                </div>
                <div class="l-article__text clearfix" v-html="article.content"></div>
            </template>
        </div>
    </div>
</template>

<script>

// import useApiMeta from '@/Plugins/meta'
import { metaMixin } from '~/mixins/metaMixin'
import XImgset from "~/components/XImgSet.vue";

export default {
    name: 'ArticleDetails',
    components: {XImgset},
    mixins: [metaMixin],
    data() {
        return {
            loading: {
                data: false,
            },
            article: {
                id: null,
                title: '',
                image: null,
                content: '',
            },
        }
    },
    async fetch () {
        await this.getData()
    },
    mounted () {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch()
        }
    },
    methods: {
        async getData () {
            let slug = this.$route.params.slug

            this.loading.data = true;
            await this.$axios.get(`/cms/posts/${slug}`)
                .then((res) => {
                    this.article = res.post
                    this.meta = res.post.seo
                    this.loading.data = false;
                    this.openGraph = [
                        {'property': 'og:title', content: this.article.name},
                        {'property': 'og:type', content: 'article'},
                        {'property': 'og:image', content: this.article.image ? this.$utils.getImage(this.article.image, 844, null) : ''},
                        {'property': 'og:description', content: this.$options.filters.truncate(this.$options.filters.stripTags(this.article.content), 200)},
                    ];
                })
                .catch((error) => {
                    console.log(error)
                    console.error(error);
                    this.loading.data = false;
                });
        },
        getPage(pageNo) {
            this.filters.page = pageNo;
            this.getData()
        },
    }
}
</script>
