<template>
    <div :class="{'loading': loading.data}">
        <div class="l-account-content__header">
            <div class="l-account-content__title">Profile</div>
        </div>
        <template v-if="user_info">
            <div class="account-info-card">
                <div class="account-info-card__header">
                    <div>Profile</div>
                    <EditIcon class="edit-btn" @click="editProfile()"/>
                </div>
                <div class="account-info-card__section">
                    <div class="account-info-card__row">
                        <div class="user-avatar">
                            <img v-if="user_info?.image" :src="$utils.getImage(user_info.image, 52, 52, false)">
                            <img v-else id="user_avatar" src="/images/avatar.png">
                            <div class="user-avatar-info">
                                <div style="font-weight: 600">{{ user_info.fullname }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Address</div>
                            <div class="account-info-card__value">{{user_info.address?.full_address}}</div>
                        </div>
                    </div>

                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Contact Number</div>
                            <div class="account-info-card__value">{{user_info.phone_prefix}} {{user_info.phone}}</div>
                        </div>
                    </div>

                    <template v-if="user.type === 'freelancer'">
                        <div class="account-info-card__row">
                            <div class="account-info-card__row-info">
                                <div class="account-info-card__label">Description</div>
                                <div class="account-info-card__value">{{user_info.profile.description}}</div>
                            </div>
                        </div>
                        <div class="account-info-card__row">
                            <div class="account-info-card__row-info">
                                <div class="account-info-card__label">Biography</div>
                                <div class="account-info-card__value">{{user_info.profile.biography}}</div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="account-info-card mt-2">
                <div class="account-info-card__header">
                    <div>Company Details</div>
                    <EditIcon class="edit-btn" @click="editCompanyDetails()"/>
                </div>
                <div class="account-info-card__section" v-if="user_info.company.id">
                    <div class="account-info-card__row">
                        <CompanyAvatar :company="user_info.company" class="mr-2"/>
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Company Name</div>
                            <div class="account-info-card__value">{{user_info.company.name}}</div>
                        </div>
                    </div>
                    <div class="account-info-card__row" v-if="user.type === 'company'">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Description</div>
                            <div class="account-info-card__value">{{user_info.company.description}}</div>
                        </div>
                    </div>
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Company Website</div>
                            <div class="account-info-card__value">{{user_info.company.website}}</div>
                        </div>
                    </div>
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Company Address</div>
                            <div class="account-info-card__value">{{user_info.company.address.full_address}}</div>
                        </div>
                    </div>
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Number Of Employees</div>
                            <div class="account-info-card__value">{{user_info.company.employees}}</div>
                        </div>
                    </div>
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Your Position In The Company</div>
                            <div class="account-info-card__value">{{user_info.company.position}}</div>
                        </div>
                    </div>
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Established data</div>
                            <div class="account-info-card__value">{{user_info.company.established_date}}</div>
                        </div>
                    </div>
                </div>
                <div class="account-info-card__section" v-else>
                    <div class="account-info-card__row">
                        <div class="account-info-card__label">No company details</div>
                    </div>
                </div>
            </div>

            <div class="account-info-card mt-2">
                <div class="account-info-card__header">
                    <div>Profile Visibility</div>
                </div>
                <div class="account-info-card__section">
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Visibility is set to</div>
                            <div class="account-info-card__value">{{ privacy | beautify }}</div>
                        </div>
                        <EditIcon class="edit-btn" @click="editPrivacy()"/>
                    </div>
                </div>
            </div>

            <div class="account-info-card mt-2" v-if="['freelancer', 'company'].indexOf(user.type) > -1">
                <div class="account-info-card__header">
                    <div>Profile Data</div>
                </div>
                <div class="account-info-card__section" v-if="user.type === 'freelancer'">
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">English Language Level</div>
                            <div class="account-info-card__value">{{ user_info.front_attributes.english_language_level?.value }}</div>
                        </div>
                        <EditIcon class="edit-btn" @click="editAttribute('english_language_level')"/>
                    </div>
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Color Code Category(s)</div>
                            <div class="account-info-card__value">
                                <ColorCodeAttribute :key="attr.value" v-for="(attr, index) in user_info.front_attributes.color_code_categories" :attr="attr"/>
                            </div>
                        </div>
                        <EditIcon class="edit-btn" @click="editAttribute('color_code_categories')"/>
                    </div>
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Services(s)</div>
                            <div class="account-info-card__value">
                                <div class="badge badge-secondary-light badge-lg mr-1 mb-1" v-for="service in user_info.front_attributes.services">{{ service.value }}</div>
                            </div>
                        </div>
                        <EditIcon class="edit-btn" @click="editAttribute('services')"/>
                    </div>
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Expertise Level</div>
                            <div class="account-info-card__value">
                                <template v-if="user_info.front_attributes.expertise_level">
                                    <img :src="user_info.front_attributes.expertise_level.icon" class="mr-1"/> {{ user_info.front_attributes.expertise_level.value }}
                                </template>
                            </div>
                        </div>
                        <EditIcon class="edit-btn" @click="editAttribute('expertise_level')"/>
                    </div>
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Expertise Area(s)</div>
                            <div class="account-info-card__value">
                                <div class="badge badge-primary-light badge-lg mr-1 mb-1" v-for="expertise_area in user_info.front_attributes.expertise_areas">{{ expertise_area.value }}</div>
                            </div>
                        </div>
                        <EditIcon class="edit-btn" @click="editAttribute('expertise_areas')"/>
                    </div>
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Availability</div>
                            <div class="account-info-card__value" v-if="user_info.front_attributes.availability">{{ user_info.front_attributes.availability.value ?? '-' }}</div>
                        </div>
                        <EditIcon class="edit-btn" @click="editAttribute('availability')"/>
                    </div>
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Hourly rate</div>
                            <div class="account-info-card__value">{{ user_info.hourly_rate | toPrice(0) }}</div>
                        </div>
                        <EditIcon class="edit-btn" @click="editHourlyRate()"/>
                    </div>
                    <div class="account-info-card__row" v-if="user.type === 'freelancer'">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Skills</div>
                            <div class="account-info-card__value">{{ user_info.profile.skills }}</div>
                        </div>
                        <EditIcon class="edit-btn" @click="editSkills()"/>
                    </div>
                    <div class="account-info-card__row" v-if="user.type === 'freelancer'">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Offering Consultation Session</div>
                            <div class="account-info-card__value">{{ user_info.front_attributes.consultation?.value ? 'Yes' : 'No' }}</div>
                        </div>
                        <EditIcon class="edit-btn" @click="editAttribute('consultation')"/>
                    </div>
                </div>
                <div class="account-info-card__section" v-if="user.type === 'company'">
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Color Code Category(s)</div>
                            <div class="account-info-card__value">
                                <ColorCodeAttribute :key="attr.value" v-for="(attr, index) in user_info.front_attributes.color_code_categories" :attr="attr"/>
                            </div>
                        </div>
                        <EditIcon class="edit-btn" @click="editAttribute('color_code_categories')"/>
                    </div>
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Biotech Sector(s) Covered</div>
                            <div class="account-info-card__value">
                                <div class="badge badge-primary-light badge-lg mr-1 mb-1" v-for="biotech_sector in user_info.front_attributes.biotech_sectors">{{ biotech_sector.value }}</div>
                            </div>
                        </div>
                        <EditIcon class="edit-btn" @click="editAttribute('biotech_sectors')"/>
                    </div>
                    <div class="account-info-card__row">
                        <div class="account-info-card__row-info">
                            <div class="account-info-card__label">Offering Jobs</div>
                            <div class="account-info-card__value">
                                <div class="account-info-card__value">{{ user_info.front_attributes.offering_jobs?.value ? 'Yes' : 'No' }}</div>
                            </div>
                            <div class="account-info-card__label mt-2">Career page</div>
                            <div class="account-info-card__value">
                                <div class="account-info-card__value">{{ user_info.career_page }}</div>
                            </div>
                        </div>
                        <EditIcon class="edit-btn" @click="editAttribute('offering_jobs')"/>
                    </div>
                </div>
            </div>

            <div class="account-info-card mt-2" v-if="['freelancer'].indexOf(user.type) > -1">
                <div class="account-info-card__header">
                    <div>Employment History</div>
                    <EditIcon class="edit-btn" @click="addObject('employment_history')"/>
                </div>
                <template v-if="user_info.profile?.employment_history?.length">
                    <div class="account-info-card__section" v-for="(employment, index) in user_info.profile.employment_history">
                            <div class="account-info-card__row --spaced">
                                <div class="c-onboarding-profile-item__type"><EmploymentIcon fill="#09CD61" height="24" width="24"/></div>
                                <div class="c-onboarding-profile-item__body">
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Job Title</div>
                                        <div class="c-onboarding-profile-item__value">{{ employment.name }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Job Type</div>
                                        <div class="c-onboarding-profile-item__value"><div class="badge badge-primary">{{ employment.type }}</div></div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Company</div>
                                        <div class="c-onboarding-profile-item__value">{{ employment.company }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Time Period</div>
                                        <div class="c-onboarding-profile-item__value">{{ employment.period.from | moment('MMM YYYY') }} - <template v-if="employment.period.to">{{ employment.period.to | moment('MMM YYYY') }}</template><template v-else>Present</template></div>
                                    </div>
                                </div>
                                <div class="c-onboarding-profile-item__actions">
                                    <EditIcon height="32" width="32" class="edit-btn" @click="editObject('employment_history', employment, index)"/>
                                    <CloseCircleIcon height="32" width="32" @click.prevent="removeObject('employment_history', index)"/>
                                </div>
                            </div>
                        </div>
                </template>
                <div class="account-info-card__section" v-else>
                    <div class="account-info-card__row">
                        <div class="account-info-card__label">No employment history</div>
                    </div>
                </div>
            </div>

            <div class="account-info-card mt-2" v-if="['freelancer'].indexOf(user.type) > -1">
                <div class="account-info-card__header">
                    <div>Education History</div>
                    <EditIcon class="edit-btn" @click="addObject('education_history')"/>
                </div>
                <template v-if="user_info.profile?.education_history?.length">
                    <div class="account-info-card__section" v-for="(education, index) in user_info.profile.education_history">
                        <div class="account-info-card__row --spaced">
                            <div class="c-onboarding-profile-item__type"><EducationIcon fill="#09CD61" height="24" width="24"/></div>
                            <div class="c-onboarding-profile-item__body">
                                <div>
                                    <div class="c-onboarding-profile-item__label">Achieved Level</div>
                                    <div class="c-onboarding-profile-item__value">{{ education.level === 'Other' ? education.level_other : education.level }}</div>
                                </div>
                                <div>
                                    <div class="c-onboarding-profile-item__label">Description</div>
                                    <div class="c-onboarding-profile-item__value">{{ education.description }}</div>
                                </div>
                                <div>
                                    <div class="c-onboarding-profile-item__label">Institution name</div>
                                    <div class="c-onboarding-profile-item__value">{{ education.institute }}</div>
                                </div>
                                <div>
                                    <div class="c-onboarding-profile-item__label">City & Country</div>
                                    <div class="c-onboarding-profile-item__value">{{ education.city_name }} {{ education.country?.name }}</div>
                                </div>
                                <div>
                                    <div class="c-onboarding-profile-item__label">Time Period</div>
                                    <div class="c-onboarding-profile-item__value">{{ education.period.from | moment('MMM YYYY') }} - <template v-if="education.period.to">{{ education.period.to | moment('MMM YYYY') }}</template><template v-else>Present</template></div>
                                </div>
                            </div>
                            <div class="c-onboarding-profile-item__actions">
                                <EditIcon height="32" width="32" class="edit-btn" @click="editObject('education_history', education, index)"/>
                                <CloseCircleIcon height="32" width="32" @click.prevent="removeObject('education_history', index)"/>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="account-info-card__section" v-else>
                    <div class="account-info-card__row">
                        <div class="account-info-card__label">No education history</div>
                    </div>
                </div>
            </div>

            <div class="account-info-card mt-2" v-if="['freelancer'].indexOf(user.type) > -1">
                <div class="account-info-card__header">
                    <div>Training Certificates</div>
                    <EditIcon class="edit-btn" @click="addObject('certificates')"/>
                </div>
                <template v-if="user_info.profile?.certificates?.length">
                    <div class="account-info-card__section" v-for="(certificate, index) in user_info.profile.certificates">
                        <div class="account-info-card__row --spaced">
                            <div class="c-onboarding-profile-item__type"><EmploymentIcon fill="#09CD61" height="24" width="24"/></div>
                            <div class="c-onboarding-profile-item__body">
                                <div>
                                    <div class="c-onboarding-profile-item__label">Certificate title</div>
                                    <div class="c-onboarding-profile-item__value">{{ certificate.title }}</div>
                                </div>
                                <div>
                                    <div class="c-onboarding-profile-item__label">Description</div>
                                    <div class="c-onboarding-profile-item__value">{{ certificate.description }}</div>
                                </div>
                                <div>
                                    <div class="c-onboarding-profile-item__label">Issued By</div>
                                    <div class="c-onboarding-profile-item__value">{{ certificate.issued_by }}</div>
                                </div>
                                <div>
                                    <div class="c-onboarding-profile-item__label">Training Period</div>
                                    <div class="c-onboarding-profile-item__value">{{ certificate.period.from | moment('MMM YYYY') }} - <template v-if="certificate.period.to">{{ certificate.period.to | moment('MMM YYYY') }}</template><template v-else>Present</template></div>
                                </div>
                            </div>
                            <div class="c-onboarding-profile-item__actions">
                                <EditIcon height="32" width="32" class="edit-btn" @click="editObject('certificates', certificate, index)"/>
                                <CloseCircleIcon height="32" width="32" @click.prevent="removeObject('certificates', index)"/>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="account-info-card__section" v-else>
                    <div class="account-info-card__row">
                        <div class="account-info-card__label">No certificates</div>
                    </div>
                </div>
            </div>

            <div class="account-info-card mt-2" v-if="['freelancer'].indexOf(user.type) > -1">
                <div class="account-info-card__header">
                    <div>Publications</div>
                    <EditIcon class="edit-btn" @click="addObject('publications')"/>
                </div>
                <template v-if="user_info.profile?.publications?.length">
                    <div class="account-info-card__section" v-for="(publication, index) in user_info.profile.publications">
                        <div class="account-info-card__row --spaced">
                            <div class="c-onboarding-profile-item__type"><PublicationIcon fill="#09CD61" height="24" width="24"/></div>
                            <div class="c-onboarding-profile-item__body">
                                <div>
                                    <div class="c-onboarding-profile-item__label">Publication Title</div>
                                    <div class="c-onboarding-profile-item__value">{{ publication.title }}</div>
                                </div>
                                <div>
                                    <div class="c-onboarding-profile-item__label">Author(s)</div>
                                    <div class="c-onboarding-profile-item__value">{{ publication.authors.join(', ') }}</div>
                                </div>
                                <div>
                                    <div class="c-onboarding-profile-item__label">Year of publication</div>
                                    <div class="c-onboarding-profile-item__value">{{ publication.year }}</div>
                                </div>
                                <div>
                                    <div class="c-onboarding-profile-item__label">Volume and Issue Number</div>
                                    <div class="c-onboarding-profile-item__value">{{ publication.volume_number }}, {{ publication.issue_number }}</div>
                                </div>
                            </div>
                            <div class="c-onboarding-profile-item__actions">
                                <EditIcon height="32" width="32" class="edit-btn" @click="editObject('publications', publication, index)"/>
                                <CloseCircleIcon height="32" width="32" @click.prevent="removeObject('publications', index)"/>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="account-info-card__section" v-else>
                    <div class="account-info-card__row">
                        <div class="account-info-card__label">No publications</div>
                    </div>
                </div>
            </div>

            <div class="account-info-card mt-2" v-if="['freelancer'].indexOf(user.type) > -1">
                <div class="account-info-card__header">
                    <div>Awards</div>
                    <EditIcon class="edit-btn" @click="addObject('awards')"/>
                </div>
                <template v-if="user_info.profile?.awards?.length">
                    <div class="account-info-card__section" v-for="(award, index) in user_info.profile.awards">
                        <div class="account-info-card__row --spaced">
                            <div class="c-onboarding-profile-item__type"><EmploymentIcon fill="#09CD61" height="24" width="24"/></div>
                            <div class="c-onboarding-profile-item__body">
                                <div>
                                    <div class="c-onboarding-profile-item__label">Award Title</div>
                                    <div class="c-onboarding-profile-item__value">{{ award.title }}</div>
                                </div>
                                <div>
                                    <div class="c-onboarding-profile-item__label">Description</div>
                                    <div class="c-onboarding-profile-item__value">{{ award.description }}</div>
                                </div>
                                <div>
                                    <div class="c-onboarding-profile-item__label">Awarded By</div>
                                    <div class="c-onboarding-profile-item__value">{{ award.awarded_by }}</div>
                                </div>
                                <div>
                                    <div class="c-onboarding-profile-item__label">Date</div>
                                    <div class="c-onboarding-profile-item__value">{{ award.date | moment('MMM YYYY') }}</div>
                                </div>
                            </div>
                            <div class="c-onboarding-profile-item__actions">
                                <EditIcon height="32" width="32" class="edit-btn" @click="editObject('awards', award, index)"/>
                                <CloseCircleIcon height="32" width="32" @click.prevent="removeObject('awards', index)"/>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="account-info-card__section" v-else>
                    <div class="account-info-card__row">
                        <div class="account-info-card__label">No awards</div>
                    </div>
                </div>
            </div>

        </template>
        <modal name="userInfoModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('userInfoModal')">
                        <CloseCircleIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Edit user profile</h4>
                    </div>
                    <div class="modal-body" v-if="edit_user">
                        <div class="mx-auto mb-2">
                            <ImagesUploaderCrop :size="320" v-model="edit_user.image" :multiple="false"/>
                        </div>
                        <FormGroup label="Prefix">
                            <BadgeSelect v-model="edit_user.prefix" :options="['Mr.', 'Mrs.', 'Miss', 'Ms.', 'Dr.', {label: 'Don\'t Add Title', value: null}]"/>
                        </FormGroup>
                        <FormInput class="mb-2" label="First Name" v-model="edit_user.firstname" :v="$v.edit_user.firstname"/>
                        <FormInput class="mb-2" label="Last Name" v-model="edit_user.lastname" :v="$v.edit_user.lastname"/>
                        <FormInput class="mb-2" type="phone" label="Contact Phone" placehoder="Mobile Number" v-model="edit_user.phone" :v="$v.edit_user.phone">
                            <template v-slot:prepend>
                                <input class="form-control-prepend" v-model="edit_user.phone_prefix" :v="$v.edit_user.phone_prefix">
                            </template>
                        </FormInput>
                        <FormGroup label="Country">
                            <CountrySelect v-model="edit_user.address.country"/>
                        </FormGroup>
<!--                        <template v-if="edit_user.type === 'freelancer'">-->
                            <FormInput class="mb-2" label="City" placeholder="" v-model="edit_user.address.city_name"/>
                            <FormInput class="mb-2" label="Province" placeholder="" v-model="edit_user.address.county_name"/>
                            <FormInput class="mb-2" label="ZIP code" placeholder="" v-model="edit_user.address.postcode"/>
<!--                        </template>-->
                        <template v-if="edit_user.type === 'freelancer'">
                            <FormTextarea class="mb-2" label="Description" v-model="edit_user.profile.description" :count="500"/>
                            <FormTextarea class="mb-2" label="Biography" v-model="edit_user.profile.biography" :count="500"/>
                        </template>
                    </div>
                    <div class="modal-footer">
                        <Btn :loading="loading.save" class="btn btn-lg btn-secondary btn-block" @click="saveUser()">Save</Btn>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="changePrivacyModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('changePrivacyModal')">
                        <CloseCircleIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Change your profile visibility</h4>
                    </div>
                    <div class="modal-body">
                        <div class="o-privacy-select mb-2" :class="{'active': edit_privacy === 'public'}" @click="edit_privacy = 'public'" v-if="user.type !== 'client'">
                            <PrivacyPublicIcon/>
                            <div class="o-privacy-select__info">
                                <div class="o-privacy-select__info-title">Public</div>
                                <div class="o-privacy-select__info-description">Anyone can see it, it will be indexed in search engines</div>
                            </div>
                        </div>
                        <div class="o-privacy-select mb-2" :class="{'active': edit_privacy === 'registered'}" @click="edit_privacy = 'registered'">
                            <PrivacyRegisteredIcon/>
                            <div class="o-privacy-select__info">
                                <div class="o-privacy-select__info-title">BOLG Users only</div>
                                <div class="o-privacy-select__info-description">Only users logged in to BOLG can find it</div>
                            </div>
                        </div>
                        <div class="o-privacy-select mb-2" :class="{'active': edit_privacy === 'private'}" @click="edit_privacy = 'private'">
                            <PrivacyPrivateIcon/>
                            <div class="o-privacy-select__info">
                                <div class="o-privacy-select__info-title">Private*</div>
                                <div class="o-privacy-select__info-description">Only BOLG admins can see it.</div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <Btn :loading="loading.save" class="btn btn-lg btn-secondary btn-block" @click="changePrivacy()">Save</Btn>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="companyModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('companyModal')">
                        <CloseCircleIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Edit company details</h4>
                    </div>
                    <div class="modal-body" v-if="edit_company">
                        <div class="mx-auto mb-2">
                            <ImagesUploaderCrop :size="320" v-model="edit_company.image" :multiple="false"/>
                        </div>

                        <FormInput class="mb-2" label="Name" v-model="edit_company.name"/>
                        <template v-if="user.type === 'company'">
                            <FormTextarea class="mb-2" label="Description"
                                          v-model="edit_company.description"
                                          :count="plan_settings.limit_description ? plan_settings.limit_description : 500"
                                          count_error="The description is to long and will be truncated."
                            />
                        </template>
                        <FormInput class="mb-2" label="Website" v-model="edit_company.website"/>
                        <FormInput class="mb-2" label="Position in the Company" v-model="edit_company.position"/>
                        <FormDate :time="false" altFormat="m/d/Y" placeholder="mm/dd/yyyy" class="mb-2" label="Established Date" :maxDate="new Date()" v-model="edit_company.established_date"/>
                        <FormInput class="mb-2" label="Address" v-model="edit_company.address.address"/>
                        <FormInput class="mb-2" label="City" v-model="edit_company.address.city_name"/>
                        <FormInput class="mb-2" label="Province" v-model="edit_company.address.county_name"/>
                        <FormInput class="mb-2" label="ZIP code" v-model="edit_company.address.postcode"/>
                        <FormGroup label="Country">
                            <CountrySelect v-model="edit_company.address.country"/>
                        </FormGroup>
                        <FormGroup label="Number of Employees">
                            <FormRadio :bordered="true" fieldValue="<15" name="employees" v-model="edit_company.employees" text="Less Than 15 Employees"/>
                            <FormRadio :bordered="true" fieldValue="15-50" name="employees" v-model="edit_company.employees" text="Between 15-50"/>
                            <FormRadio :bordered="true" fieldValue="50-100" name="employees" v-model="edit_company.employees" text="50-100 Employees"/>
                            <FormRadio :bordered="true" fieldValue="100-500" name="employees" v-model="edit_company.employees" text="100-500 Employees"/>
                            <FormRadio :bordered="true" fieldValue=">500" name="employees" v-model="edit_company.employees" text="More than 500 Employees"/>
                        </FormGroup>
                    </div>
                    <div class="modal-footer">
                        <Btn :loading="loading.save" class="btn btn-lg btn-secondary btn-block" @click="saveCompany()">Save</Btn>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="hourlyRateModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('hourlyRateModal')">
                        <CloseCircleIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Edit hourly rate</h4>
                    </div>
                    <div class="modal-body">
                        <ClientOnly>
                            <vue-slider v-model="edit_hourly_rate"
                                        :min="0"
                                        :max="1000"
                                        :dotSize="28"
                                        :height="2"
                                        :tooltip="'always'"
                                        :tooltip-formatter="val => `$${val}`"
                            ></vue-slider>
                        </ClientOnly>
                    </div>
                    <div class="modal-footer">
                        <Btn :loading="loading.save" class="btn btn-lg btn-secondary btn-block" @click="saveHourlyRate()">Save</Btn>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="skillsModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('skillsModal')">
                        <CloseCircleIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Your Skills & Experience</h4>
                    </div>
                    <div class="modal-body">
                        <FormTextarea v-model="edit_skills.skills" placeholder="Description" :count="500"/>
                        <DocumentsUploader  v-model="edit_skills.skill_documents"/>
                    </div>
                    <div class="modal-footer">
                        <Btn :loading="loading.save" class="btn btn-lg btn-secondary btn-block" @click="saveSkills()">Save</Btn>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="attributeModal" height="auto" :scrollable="true" style="border-radius: 10px" :clickToClose="false">
            <div class="modal-dialog">
                <div class="modal-content" v-if="edit_attribute">
                    <a style="cursor: pointer" class="close" @click="$modal.hide('attributeModal')">
                        <CloseCircleIcon class="close"/>
                    </a>
                    <div class="modal-header">
                        <h4 class="modal-title">Edit {{ edit_attribute.name }}</h4>
                    </div>
                    <div class="modal-body">
                        <template v-if="edit_attribute.attribute === 'english_language_level'">
                            <FormRadio :bordered="true" v-for="option in attributes.english_language_level"
                                       :fieldValue="option.id"
                                       :key="option.id"
                                       name="english_language_level"
                                       v-model="edit_attribute.value"
                                       :text="option.name"/>
                        </template>
                        <template v-if="edit_attribute.attribute === 'education'">
                            <FormRadio :bordered="true" v-for="option in attributes.education"
                                       :fieldValue="option.id"
                                       :key="option.id"
                                       name="education"
                                       v-model="edit_attribute.value"
                                       :text="option.name"/>
                        </template>
                        <template v-if="edit_attribute.attribute === 'availability'">
                            <FormRadio :bordered="true" v-for="option in attributes.availability"
                                       :fieldValue="option.id"
                                       :key="option.id"
                                       name="availability"
                                       v-model="edit_attribute.value"
                                       :text="option.name"/>
                        </template>
                        <template v-if="edit_attribute.attribute === 'expertise_level'">
                            <FormRadio :bordered="true" v-for="option in attributes.expertise_level"
                                       :fieldValue="option.id"
                                       :key="option.id"
                                       name="expertise_level"
                                       v-model="edit_attribute.value"
                                       :text="option.name"/>
                        </template>
                        <template v-if="edit_attribute.attribute === 'expertise_areas'">
                            <div class="pb-2">
                                <div class="badge badge-primary-light badge-lg mr-1 mb-1" v-for="(expertise_area, index) in edit_attribute.value">{{ expertise_area.name }} <a @click.prevent="() => edit_attribute.value.splice(index, 1)">X</a></div>
                            </div>
                            <AttributeSelect attribute_code="expertise_areas" class="mb-1" :searchOnly="true" @select="(option) => edit_attribute.value.push(option)"/>
                        </template>
                        <template v-if="edit_attribute.attribute === 'biotech_sectors'">
                            <div class="pb-2">
                                <div class="badge badge-primary-light badge-lg mr-1 mb-1" v-for="(biotech_sector, index) in edit_attribute.value">{{ biotech_sector.name }} <a @click.prevent="() => edit_attribute.value.splice(index, 1)">X</a></div>
                            </div>
                            <AttributeSelect attribute_code="biotech_sectors" class="mb-1" :searchOnly="true" @select="(option) => edit_attribute.value.push(option)"/>
                        </template>
                        <template v-if="edit_attribute.attribute === 'offering_jobs'">
                            <div class="d-flex flex-row">
                                <FormRadio class="mr-2" name="offering_jobs" text="Yes" size="lg" :fieldValue="1" v-model="edit_attribute.value"/>
                                <FormRadio class="mr-2" name="offering_jobs" text="No" size="lg" :fieldValue="0" v-model="edit_attribute.value"/>
                            </div>
                            <FormInput class="mb-2" label="Link to career Page" placeholder="Https://" v-model="edit_meta.value"/>
                        </template>
                        <template v-if="edit_attribute.attribute === 'consultation'">
                            <div class="d-flex flex-row">
                                <FormRadio class="mr-2" name="consultation" text="Yes" size="lg" :fieldValue="1" v-model="edit_attribute.value"/>
                                <FormRadio class="mr-2" name="consultation" text="No" size="lg" :fieldValue="0" v-model="edit_attribute.value"/>
                            </div>
                        </template>
                        <template v-if="edit_attribute.attribute === 'services'">
                            <div class="row">
                                <div class="col-md-6" v-for="option in attributes.services">
                                    <FormCheckbox
                                        :id="'services' + option.id"
                                        :fieldValue="option"
                                        :key="option.id"
                                        name="services"
                                        size="lg"
                                        v-model="edit_attribute.value"
                                        :text="option.name"/>
                                </div>
                            </div>
                        </template>
                        <template v-if="edit_attribute.attribute === 'color_code_categories'">
                            <FormCheckbox v-for="option in attributes.color_code_categories"
                                          :fieldValue="option"
                                          :key="option.id"
                                          :id="'color_code_categories' + option.id"
                                          name="color_code_categories"
                                          v-model="edit_attribute.value"
                            >
                                <template v-slot:labelslot>
                                    <div class="o-attribute-option" >
                                        <div class="o-attribute-option__icon" v-if="option.icon">
                                            <XImgset class="img-fluid" :src="option.icon" :width="20" :height="20" :alt="option.name"/>
                                        </div>
                                        <div>
                                            <div class="o-attribute-option__title">{{ option.name }}</div>
                                            <div class="o-attribute-option__desc" v-if="option.subtitle">{{ option.subtitle }}</div>
                                        </div>
                                    </div>
                                </template>
                            </FormCheckbox>
                        </template>
                    </div>
                    <div class="modal-footer">
                        <Btn :loading="loading.save" class="btn btn-lg btn-secondary btn-block" @click="saveAttribute()">Save</Btn>
                    </div>
                </div>
            </div>
        </modal>
        <ModalSidebar name="editObjectModal" backLabel="Cancel">
            <template v-if="edit_profile_object">
                <div class="card">
                    <div class="card-body">
                        <EducationHistoryForm
                            v-if="edit_profile_object_type === 'education_history'"
                            :value="edit_profile_object"
                            :profile_id="user_info.profile.id"
                            :loading_save="loading.save"
                            @onSave="saveObject"
                        />
                        <EmploymentHistoryForm
                            v-else-if="edit_profile_object_type === 'employment_history'"
                            :value="edit_profile_object"
                            :profile_id="user_info.profile.id"
                            :loading_save="loading.save"
                            @onSave="saveObject"
                        />
                        <CertificatesForm
                            v-else-if="edit_profile_object_type === 'certificates'"
                            :value="edit_profile_object"
                            :profile_id="user_info.profile.id"
                            :loading_save="loading.save"
                            @onSave="saveObject"
                        />
                        <PublicationsForm
                            v-else-if="edit_profile_object_type === 'publications'"
                            :value="edit_profile_object"
                            :profile_id="user_info.profile.id"
                            :loading_save="loading.save"
                            @onSave="saveObject"
                        />
                        <AwardsForm
                            v-else-if="edit_profile_object_type === 'awards'"
                            :value="edit_profile_object"
                            :profile_id="user_info.profile.id"
                            :loading_save="loading.save"
                            @onSave="saveObject"
                        />
                    </div>
                </div>
            </template>
        </ModalSidebar>
    </div>
</template>

<script>

import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Tabs from "~/components/Tabs.vue";
import Tab from "~/components/Tab.vue";
import UserAvatar from "~/components/UserAvatar.vue";
import FormInput from "~/components/FormInput.vue";
import Btn from "~/components/Btn.vue";
import CountrySelect from "~/components/CountrySelect.vue";
import FormRadio from "~/components/FormRadio.vue";
import ImagesUploaderCrop from "~/components/ImageUploaderCrop.vue";
import BadgeSelect from "~/components/BadgeSelect.vue";
import FormDate from "~/components/FormDate.vue";
import CompanyAvatar from "~/components/CompanyAvatar.vue";
import ColorCodeAttribute from "~/components/ColorCodeAttribute.vue";
import FormCheckbox from "~/components/FormCheckbox.vue";
import XImgset from "~/components/XImgSet.vue";
import FormTextarea from "~/components/FormTextarea.vue";
import AttributeSelect from "~/components/AttributeSelect.vue";

import ApplicationDetails from "@/pages/Account/ApplicationDetails.vue";
import EducationHistoryForm from "@/partials/EducationHistoryForm.vue";

import {profile_objects} from '~/utils/consts.js'
import EmploymentHistoryForm from "@/partials/EmploymentHistoryForm.vue";
import CertificatesForm from "@/partials/CertificatesForm.vue";
import PublicationsForm from "@/partials/PublicationsForm.vue";
import AwardsForm from "@/partials/AwardsForm.vue";
import ModalSidebar from "@/components/ModalSidebar.vue";
import DocumentsUploader from "@/components/DocumentsUploader.vue";
import {metaMixin} from "@/mixins/metaMixin";

export default {
    name: 'AccountInfo',
    components: {
        DocumentsUploader,
        ModalSidebar,
        AwardsForm,
        PublicationsForm,
        CertificatesForm,
        EmploymentHistoryForm,
        EducationHistoryForm,
        ApplicationDetails,
        AttributeSelect,
        FormTextarea,
        XImgset,
        FormCheckbox,
        ColorCodeAttribute, CompanyAvatar, FormDate, BadgeSelect, ImagesUploaderCrop, FormRadio, CountrySelect, Btn, FormInput,
        UserAvatar, Tab, Tabs,
    },
    data() {
        return {
            loading: {
                data: false,
                save: false,
            },
            user_info: null,
            edit_user: null,
            edit_company: null,
            privacy: null,
            edit_privacy: null,
            edit_hourly_rate: null,
            edit_skills: {
                skills: '',
                skill_documents: []
            },
            attributes: {},
            edit_attribute: null,
            edit_meta: null,

            edit_profile_object_type: null,
            edit_profile_object_index: null,
            edit_profile_object: null,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
            plan_settings: state => state.plan_settings,
        }),
    },
    validations: {
        edit_user: {
            firstname: {required},
            lastname: {required},
            phone: {},
            phone_prefix: {},
        },
    },
    watch: {
        'user.id'() {
            this.getData();
        },
    },
    mounted () {
        if (this.user.id) {
            this.getData();
        }
    },
    methods: {
        editProfile() {
            this.edit_user = JSON.parse(JSON.stringify(this.user_info));
            this.$modal.show('userInfoModal')
        },
        saveUser () {
            this.$v.edit_user.$touch();
            if (this.$v.edit_user.$invalid == true) {
                return false;
            }

            let data = {
                prefix: this.edit_user.prefix,
                firstname: this.edit_user.firstname,
                lastname: this.edit_user.lastname,
                image: this.edit_user.image,
                phone_prefix: this.edit_user.phone_prefix,
                phone: this.edit_user.phone,
                address: this.edit_user.address,
            }

            if (this.user.type === 'freelancer') {
                data.profile = {
                    biography: this.edit_user.profile.biography,
                    description: this.edit_user.profile.description,
                }
            }

            this.loading.save = true;
            this.$axios.post("/account/save-info", data)
                .then((res) => {
                    this.getAccountInfo();
                    this.$modal.hide('userInfoModal')
                    this.loading.save = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.save = false;
                });
        },
        editCompanyDetails() {
            this.edit_company = JSON.parse(JSON.stringify(this.user_info.company));
            this.$modal.show('companyModal')
        },
        saveCompany () {
            this.loading.save = true;
            this.$axios.post("/account/save-company", this.edit_company)
                .then((res) => {
                    this.$store.commit('setUser', {
                        company: res.company,
                        isAgency: res.isAgency
                    })
                    this.user_info.company = res.company;

                    this.$modal.hide('companyModal')
                    this.loading.save = false;
                })
                .catch((error) => {
                    console.error(error);
                    console.log(error)
                    this.loading.save = false;
                });
        },
        editPrivacy() {
            this.edit_privacy = JSON.parse(JSON.stringify(this.user.privacy));
            this.$modal.show('changePrivacyModal')
        },
        changePrivacy () {
            this.loading.save = true;
            this.$axios.post("/account/save-privacy", {privacy: this.edit_privacy})
                .then((res) => {
                    this.loading.save = false;
                    this.privacy = this.edit_privacy
                    this.$store.commit('setUser', {privacy: this.edit_privacy})
                    this.$modal.hide('changePrivacyModal')
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.save = false;
                });
        },
        editHourlyRate() {
            this.edit_hourly_rate = JSON.parse(JSON.stringify(this.user_info.hourly_rate));
            this.$modal.show('hourlyRateModal')
        },
        saveHourlyRate () {
            this.loading.save = true;
            this.$axios.post("/account/save-hourly-rate", {hourly_rate: this.edit_hourly_rate})
                .then((res) => {
                    this.loading.save = false;
                    this.user_info.hourly_rate = this.edit_hourly_rate
                    this.$modal.hide('hourlyRateModal')
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        },
        editSkills() {
            this.edit_skills.skills = JSON.parse(JSON.stringify(this.user_info.profile.skills));
            this.edit_skills.skill_documents = JSON.parse(JSON.stringify(this.user_info.profile.skill_documents));
            this.$modal.show('skillsModal')
        },
        saveSkills () {
            this.loading.save = true;
            this.$axios.post("/account/save-profile", this.edit_skills)
                .then((res) => {
                    this.loading.save = false;
                    this.user_info.profile.skills = this.edit_skills.skills
                    this.user_info.profile.skill_documents = this.edit_skills.skill_documents
                    this.$modal.hide('skillsModal')
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        },
        async getData() {
            this.loading.data = true;
            await this.$axios.post("/api/get-attributes-options", {
                attribute_set: this.user.type
                // attribute_code: ['english_language_level', 'education', 'color_code_categories', 'services', 'expertise_level', 'expertise_areas', 'rate', 'availability']
            })
                .then((res) =>{
                    this.attributes = res;
                })

            await this.getAccountInfo();
            this.loading.data = false;
        },
        async getAccountInfo() {
            this.loading.data = true;
            await this.$axios.get("/account/get-info")
                .then((res) =>{
                    this.user_info = res.data
                    this.privacy = res.data.privacy
                    if (!this.user.company) {
                        this.user_info.company = {
                            name: '',
                            website: '',
                            employees: null,
                            logo: null,
                            position:null,
                            established_date:null,
                            address: {
                                address: '',
                                city_name: '',
                                county_name: '',
                                postcode: '',
                                country: null,
                            },
                            documents:[],
                        }
                    }
                    this.$store.commit('setUser', {
                        first_name: res.data.first_name,
                        last_name: res.data.last_name,
                        // username: res.data.username,
                        phone_prefix: res.data.phone_prefix,
                        phone: res.data.phone,
                        image: res.data.image,
                        address: res.data.address,
                    });
                });
            this.loading.data = false;
        },
        editAttribute(attribute, name = null) {
            this.edit_attribute = {
                attribute: attribute,
                name: name ? name : this.$options.filters.beautify(attribute),
                value: JSON.parse(JSON.stringify(this.user_info.attributes[attribute]))
            };
            if (attribute === 'offering_jobs') {
                this.edit_meta = {
                    field: 'career_page',
                    value: JSON.parse(JSON.stringify(this.user_info.career_page))
                };
            }
            this.$modal.show('attributeModal');
        },
        async saveAttribute () {
            this.loading.save = true;

            this.$axios.post("/account/save-attribute", this.edit_attribute)
                .then((res) => {
                    this.loading.save = false;
                    this.$modal.hide('attributeModal')
                })
                .catch((error) => {
                    this.loading.save = false;
                    return;
                });

            if (this.edit_attribute.attribute === 'offering_jobs') {
                this.$axios.post("/account/save-meta", this.edit_meta)
                    .then((res) => {
                        this.loading.save = false;
                    })
                    .catch((error) => {
                        this.loading.save = false;
                    });
            }
            this.getAccountInfo();
            this.$modal.hide('attributeModal')
        },
        addObject(type) {
            this.edit_profile_object_index = null
            this.edit_profile_object_type = type
            this.edit_profile_object = JSON.parse(JSON.stringify(profile_objects[type]))
            this.$modal.show('editObjectModal')
        },
        editObject(type, object, index) {
            this.edit_profile_object_index = index
            this.edit_profile_object_type = type
            this.edit_profile_object = {...profile_objects[type], ...JSON.parse(JSON.stringify(object))}
            this.$modal.show('editObjectModal')
        },
        async saveObject(new_object) {
            this.loading.save = true;
            let profile = JSON.parse(JSON.stringify(this.user_info.profile))
            if(this.edit_profile_object_index === null) {
                if (!profile[this.edit_profile_object_type]) {
                    profile[this.edit_profile_object_type] = [];
                }
                profile[this.edit_profile_object_type].push(JSON.parse(JSON.stringify(new_object)));
            }else{
                profile[this.edit_profile_object_type][this.edit_profile_object_index] = JSON.parse(JSON.stringify(new_object))
            }
            let object = {};
            object[this.edit_profile_object_type] = profile[this.edit_profile_object_type];
            await this.$axios.post("/account/save-profile", object)
                .then((res) => {
                    this.loading.save = false;
                    if(this.edit_profile_object_index === null) {
                        this.user_info.profile[this.edit_profile_object_type].push(JSON.parse(JSON.stringify(new_object)))
                    }else{
                        this.user_info.profile[this.edit_profile_object_type][this.edit_profile_object_index] = JSON.parse(JSON.stringify(new_object))
                    }
                    this.edit_profile_object_index = null
                    this.edit_profile_object_type = null
                    this.edit_profile_object = null
                    this.$modal.hide('editObjectModal')
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        },
        removeObject(type, index) {
            this.$dialog.confirm('Are you sure?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    this.user_info.profile[type].splice(index, 1);
                }).catch(e => {
            });
        },
    }
}
</script>
