<template>
    <div class="chat-wrapper" v-if="conversation">
        <div class="chat-wrapper__header" :class="{'--show-info': showUserInfo}">
            <div class="chat-wrapper__back" @click="$emit('deselectConversation')">
                <ChevronLeftIcon height="50" width="50"/>
            </div>
            <div style="position: relative">
                <UserAvatar :user="conversation.user" class="chat-list__item__image"/>
<!--                <img :src="$utils.getImage(conversation.user.image, 52, 52, false)" class="chat-wrapper__header__image" :class="{'active': conversation.unread_count > 0}"/>-->
                <span v-if="conversation.is_online" class="chat-wrapper__header__online-dot"></span>
            </div>
            <div class="chat-wrapper__header-info">
                <div class="chat-wrapper__header-name">{{ conversation.user.isAgency ? conversation.user.company.name : conversation.user.fullname }}</div>
                <div class="chat-wrapper__header-project"><template v-if="conversation.project"><b>Project: </b>{{ conversation.project.title }}</template><template v-else>Main discussion</template></div>
            </div>
        </div>
        <div class="chat-body" :class="{'loading': loading.messages}">
            <div ref="chat" name="message-appear" class="chat-body__messages" v-chat-scroll="{always: false, smooth: true}" :options="{scrollbars: true}">
                <div style="text-align: center" v-if="messages && messages_count > messages.length"><a href="#" @click.prevent="loadMore" class="chat-body__load-more">Load more</a></div>
                <div class="chat-item-wrapper" :key="message.id" v-for="(message, index) in messages" v-if="messages && messages.length">
                    <ChatMessage :key="message.id" :user_visible="showUser(message, index)" @onDelete="removeMessage(index)" @onEdit="editMessage(message, index)" :date_visible="showDate(message, index)" :message="message" />
                </div>
                <div class="text-muted text-center my-2" v-else-if="messages && !messages.length">There are no messages with {{ conversation.user.fullname }}</div>
            </div>
        </div>
        <div class="chat-footer">
            <TextareaAutosize :maxHeight="$mq === 'xs' ? 100 : 124"
                              :minHeight="$mq === 'xs' ? 60 : 80"
                              ref="messageTextarea"
                              type="text"
                              class="chat-footer-input"
                              v-model.trim="message"
                              v-on:enter="sendMessage"
                              placeholder="Start typing..."
            />
            <label :class="{'loading-btn': loading.uploading}" href="#" class="btn btn-sm" for="fileupload">
                <AttachIcon width="18" height="18"/>
                <input type="file" style="display: none" name="file" id="fileupload" @change="uploadFile" :disabled="loading.uploading" ref="input">
            </label>
            <Btn href="#" class="btn-secondary" :disabled="!message.length" @keydown.esc="cancelEditMessage" :loading="loading.send" @click="sendMessage">Send</Btn>
        </div>
    </div>
</template>

<script>
    import ChatMessage from "./ChatMessage.vue";
    import findIndex from "lodash/findIndex";
    import TextareaAutosize from "../TextareaAutoresize.vue";
    import dayjs from "dayjs";
    import Btn from "../Btn.vue";
    import UserAvatar from "../UserAvatar.vue";

    export default {
        name: 'ChatDiscussion',
        components: {
            UserAvatar,
            Btn,
            TextareaAutosize,
            ChatMessage,
        },
        props: {
            'conversation': {
                default: null
            },
            'embeded': {
                default: false
            },
            'showUserInfo': {
                default: true
            }
        },
        data () {
            return {
                loading: {
                    load_more: false,
                    uploading: false,
                    messages: false,
                    messages_before: false,
                    send: false
                },
                last_updated_at: null,
                last_message_id: null,
                before_message_id: null,
                message_id: null,
                message: '',
                file: null,
                messages: null,
                messages_count: 0,
                pollingMessagesRequest: null,
                retries: 0,
            }
        },
        beforeDestroy() {
            this.$echo.leave(`Chat.${this.conversation.id}`);

            // if (this.pollingMessagesRequest && this.pollingMessagesRequest.signal) {
            //     if (!this.pollingMessagesRequest.signal.aborted) {
            //         this.pollingMessagesRequest.abort();
            //     }
            // }
        },
        async mounted() {
            await this.getMessages({}, () => {
                setTimeout(() => {
                    if (document.querySelector(".chat-body__messages")) {
                        document.querySelector(".chat-body__messages").scroll({
                            top: 20000,
                            behavior: 'smooth'
                        });
                    }
                }, 300);
            });
            this.startListeners();
            // this.startPollingMessages();
        },
        watch: {
            conversation(newValue, oldValue) {
                this.getMessages({}, () => {
                    setTimeout(() => {
                        if (document.querySelector(".chat-body__messages")) {
//                            this.$refs.chat.scrollTo(0, this.$refs.chat.maxScrollY, 0);
//                            this.$refs.chat.refresh()
                            document.querySelector(".chat-body__messages").scroll({
                                top: 20000,
                                behavior: 'smooth'
                            });
                        }
                    }, 300);
                });
                this.$echo.leave(`Chat.${oldValue.id}`);
                this.startListeners();
            }
        },
        methods: {
            startListeners() {
                this.$echo.private(`Chat.${this.conversation.id}`)
                    .listen('.MessageUpdated', (e) => {
                        let index = this.messages.findIndex(m => m.id === e.message.id)
                        if (index > -1) {
                            this.messages[index] = e.message;
                        }
                    })
                    .listen('.NewMessage', (e) => {
                        // this.getMessages();
                        this.messages.push(e.message)
                        setTimeout(() => {
                            if (document.querySelector(".chat-body__messages")) {
                                document.querySelector(".chat-body__messages").scroll({
                                    top: 20000,
                                    behavior: 'smooth'
                                });
                            }
                        }, 300);
                    })
                    .listen('.MessageDeleted', (e) => {
                        let index = this.messages.findIndex(m => m.id === e.id)
                        if (index > -1) {
                            this.messages.splice(index, 1);
                        }
                    });
            },
            startPollingMessages() {
                if (!this.conversation?.id) {
                    return;
                }
                console.log('startPollingMessages');
                if (this.pollingMessagesRequest && this.pollingMessagesRequest.signal) {
                    if (!this.pollingMessagesRequest.signal.aborted) {
                        this.pollingMessagesRequest.abort();
                    }
                }else {
                    console.log('add AbortController')
                }
                this.pollingMessagesRequest = new AbortController();

                this.$axios.post("/messaging/poll-messages", {
                    'last_message_id': this.last_message_id,
                    'conversation_id': this.conversation.id
                }, {
                        signal: this.pollingMessagesRequest.signal
                    })
                    .then((res) => {
                        if (res.not_changed === undefined && res.messages && res.messages.length > 0) {
                            res.messages.forEach((message) => {
                                this.messages.push(message);
                            });
                            this.last_message_id = res.messages[res.messages.length - 1].id;
                            this.$emit('newMessagesCount', res.messages.length);
                        }
                        this.startPollingMessages();
                    })
                    .catch((error) => {
                        console.log({error});
                        if (this.retries <= 3) {
                            this.retries++;
                            this.getMessages();
                        }
                    });
            },
            getMessages(filters, success) {
                if (this.loading.send || this.loading.messages) {
                    return false;
                }
                filters = filters || {};
                let data = Object.assign({ref: this.conversation.ref}, filters);
                this.loading.messages = true;
                return this.$axios.post("/messaging/conversation/messages", data)
                    .then((res) => {
                        if (filters.before_id) {
                            let index = findIndex(this.messages, {id: filters.before_id});
                            let messages = res.messages.reverse();
                            messages.forEach((message) => {
                                this.messages.splice(index, 0, message);
                            });
                        }else if (filters.after_id) {

                        }else{
                            this.messages = res.messages;
                            if (res.messages.length > 0) {
                                this.last_message_id = res.messages[res.messages.length - 1].id;
                            }
                        }
                        this.$emit('messages-loaded');
                        this.messages_count = res.messages_count;
                        if (res.before_message_id) {
                            this.before_message_id = res.before_message_id;
                        }
                        this.loading.messages = false;

                        if (success) {
                            success();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading.messages = false;
                    });
            },
            loadMore() {
                if (this.loading.load_more) {
                    return false;
                }
                this.getMessages({before_id : this.messages[0].id});
            },
            loadBefore(message_id) {
                this.getMessages({before_id : message_id});
            },
            loadAfter(message_id) {
                this.getMessages({after_id : message_id});
            },
            openFile() {

            },
            sendMessage() {
                if (this.message_id) {
                    return this.updateMessage()
                }
                if (!this.message) {
                    return false;
                }
                this.loading.send = true;
                this.$axios.post("/messaging/send-message", {
                    ref: this.conversation.ref,
                    username: this.conversation.username,
                    project_id: this.conversation.project?.id,
                    message: this.message
                })
                    .then((res) => {
                        this.loading.send = false;
                        // if (this.pollingMessagesRequest === null) {
                        //     this.getMessages();
                        // }
                        if (!this.conversation.id) {
                            this.$emit('onInitConversation', res.conversation)
                        }
                        this.message = '';
                    })
                    .catch(() => {
                        this.loading.send = false;
                    });
            },
            updateMessage() {
                if (!this.message) {
                    return false;
                }
                this.loading.send = true;
                this.$axios.post("/messaging/edit-message", {
                    id: this.message_id,
                    message: this.message
                })
                    .then((res) => {
                        this.loading.send = false;
                        const index = this.messages.findIndex(m => m.id === this.message_id);
                        if (index) {
                            this.messages[index].message = res.message.message;
                            this.messages[index].file = res.message.file
                            this.messages[index].type = res.message.type
                        }

                        this.message = '';
                        this.message_id = null;
                    })
                    .catch(() => {
                        this.loading.send = false;
                    });
            },
            uploadFile(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length) return;
                const file = files[0];
                var formData = new FormData();
                formData.append('ref', this.conversation.ref);
                formData.append('file', file);
                this.loading.uploading = true;

                this.$axios.post("/messaging/chat-upload", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    emulateJSON: true,
                    files: true
                }).then((res) => {
                    // if (this.pollingMessagesRequest === null) {
                    //     this.getMessages({}, () => {
                    //         this.startPollingMessages();
                    //     });
                    // }
                    this.$refs.input.value = '';
                    this.loading.uploading = false;
                })
                .catch(() => {
                    this.loading.uploading = false;
                });
            },
            differentDay(date1, date2) {
                return !dayjs(date1).isSame(date2, 'day');
            },
            close() {
                this.$emit('close');
            },
            showDate(message, index) {
                if (this.messages[index - 1] === undefined) {
                    return true
                }
                const diff = dayjs(message.date).diff(dayjs(this.messages[index - 1].date), 'minutes');
                if(diff > 25) {
                    return true
                }
                // if(this.messages[index-1].me !== message.me && diff > 25) {
                //     return true
                // }

                return false
            },
            showUser(message, index) {
                // if (this.messages[index - 1] !== undefined && this.messages[index - 1].me !== message.me) {
                //     return true
                // }

                return false;
            },
            editMessage(message, index) {
                this.message_id = message.id
                this.message = message.message
                if (this.$refs['messageTextarea']) {
                    this.$refs['messageTextarea'].$el.focus()
                }
            },
            cancelEditMessage() {
                this.message_id = null
                this.message = ''
            },
            removeMessage(index) {
                this.messages.splice(index, 1);
            },
        }
    }
</script>
