<template>
    <div class="d-flex flex-column" style="flex: 1;">
        <div class="l-auth__onboarding" :class="{'loading': loading.data, '--white-bg': finished, '--gray-bg': preview}">
            <Bubbles class="l-auth__onboarding-bubbles"/>
            <div class="container" v-if="preview">
                <FreelancerDetails :profile="user_data" :preview="true"/>
            </div>
            <OnboardingCard
                    v-else-if="finished"
                    title="Success! Your profile is ready"
                    style="width: 680px"
                    icon="/images/success_profile.svg"
            >
                <div class="mx-auto" style="max-width: 488px">
                    <div class="l-auth__onboarding__success-card mb-4">
                        <div class="l-auth__onboarding__success-card__item"><CheckIcon/> You can only have one account with BOLG</div>
                        <div class="l-auth__onboarding__success-card__item"><CheckIcon/> Only your country and city will be visible to clients</div>
                        <div class="l-auth__onboarding__success-card__item"><CheckIcon/> Your phone number will not be visible to clients</div>
                    </div>
                    <div class="alert-outline mb-4">
                        <p>We will review your profile within 24 or 48 hours to approve it and accept it.</p>
                        <p>For verification, you may have a quick Skype or Zoom call to verify that you are the person who filled the profile. We will ask you about your name, full address, phone number, your education, employment history, etc.</p>
                    </div>
                    <div class="text-center">
                        <div>
                            <Btn class="btn-outline-light --w380 mb-2" :to="{name: 'freelancers.details', params: {username: user_data.username}}">View My Profile</Btn>
                        </div>
                        <div>
                            <Btn class="btn-secondary --w380 mb-2" :to="{name: 'jobs.list'}">Find Biotech Job</Btn>
                        </div>
                    </div>
                </div>
            </OnboardingCard>
            <OnboardingCard
                v-else-if="edit_object && edit_type === 'employment_history'"
                title="Employment History"
                subtitle="Enter your employment history starting from recent jobs "
                style="width: 555px"
            >
                <div>
                    <FormInput class="mb-2" label="Job title" placehoder="Jon Title" v-model="edit_object.name"/>
                    <BadgeSelect :options="['Full Time', 'Part Time', 'Self Employed', 'Freelancer', 'Internship']" v-model="edit_object.type"/>
                    <FormTextarea class="mb-2" label="Description" :count="250" placehoder="Description" v-model="edit_object.description"/>
                    <FormInput class="mb-2" label="Company Name if Applicable" placeholder="Company Name" v-model="edit_object.company"/>
                    <FormInput class="mb-2" label="City" v-model="edit_object.city_name"/>
                    <FormInput class="mb-2" label="Province" v-model="edit_object.county_name"/>
                    <FormGroup label="Country">
                        <CountrySelect class="mb-2" v-model="edit_object.country"/>
                    </FormGroup>
                    <FormDate class="mb-2" altFormat="m/d/Y" placeholder="mm/dd/yyyy" label="From" :time="false" v-model="edit_object.period.from"/>
                    <FormDate class="mb-2" altFormat="m/d/Y" placeholder="mm/dd/yyyy" label="To" :time="false" v-model="edit_object.period.to" v-if="!edit_object.period.present"/>
                    <FormCheckbox text="I still work here" size="lg" v-model="edit_object.period.present" @change="(value) => value ? edit_object.period.to = null : null"/>
                    <FormGroup label="Upload Document if Applicable">
                        <DocumentsUploader class="mb-2" v-model="edit_object.documents" role="employment_history" target="Modules\Clients\Entities\ClientProfile" :target_id="user_data.profile.id"/>
                    </FormGroup>
                    <div><Btn class="btn-block btn-secondary-light mb-2" @click="saveObject">{{ edit_index !== null ? 'Save' : 'Add' }}</Btn></div>
                    <div><Btn class="btn-block btn-outline-light" @click="cancelEditObject">Cancel</Btn></div>
                </div>
            </OnboardingCard>
            <OnboardingCard
                v-else-if="edit_object && edit_type === 'education_history'"
                title="Education History"
                subtitle="Enter details for each of your completed college and university degree starting from more recent degree. If you already have an ORCID profile, simply sync with ORCID to populate your profile"
                style="width: 555px"
            >
                <div>

                    <button @click="AuthProvider('orcid')" class="btn btn-outline-light mb-3 btn-block ml-0">
                        <ORCIDIcon style="margin-right: 4px"/>
                        Connect with ORCID account
                    </button>

                    <div class="word-hr mb-3"><span>or fill manually</span></div>
                    <FormGroup label="Achieved Level">
                        <FormRadio :bordered="true" fieldValue="High School Diploma" name="achieved" v-model="edit_object.level" text="High School Diploma"/>
                        <FormRadio :bordered="true" fieldValue="Undergraduate Student" name="achieved" v-model="edit_object.level" text="Undergraduate Student"/>
                        <FormRadio :bordered="true" fieldValue="Graduate Student/Researcher" name="achieved" v-model="edit_object.level" text="Graduate Student/Researcher"/>
                        <FormRadio :bordered="true" fieldValue="Bachelor" name="achieved" v-model="edit_object.level" text="Bachelor"/>
                        <FormRadio :bordered="true" fieldValue="Msc" name="achieved" v-model="edit_object.level" text="Msc"/>
                        <FormRadio :bordered="true" fieldValue="PhD" name="achieved" v-model="edit_object.level" text="PhD"/>
                        <FormRadio :bordered="true" fieldValue="Other" name="achieved" v-model="edit_object.level" text="Other"/>
                    </FormGroup>
                    <FormInput v-if="edit_object.level === 'Other'" class="mb-2" v-model="edit_object.level_other"/>
                    <FormTextarea class="mb-2" label="Description" :count="50" placehoder="Description" v-model="edit_object.description"/>
                    <FormInput class="mb-2" label="Institution Name" placeholder="Institution Name" v-model="edit_object.institute"/>
                    <FormInput class="mb-2" label="City" v-model="edit_object.city_name"/>
                    <FormGroup label="Country">
                        <CountrySelect class="mb-2" v-model="edit_object.country"/>
                    </FormGroup>
                    <FormDate class="mb-2" altFormat="m/d/Y" placeholder="mm/dd/yyyy" label="From" :time="false" v-model="edit_object.period.from"/>
                    <FormDate class="mb-2" altFormat="m/d/Y" placeholder="mm/dd/yyyy" label="To" :time="false" v-model="edit_object.period.to" v-if="!edit_object.period.present"/>
                    <FormCheckbox text="I currently study here" size="lg" v-model="edit_object.period.present" @change="(value) => value ? edit_object.period.to = null : null"/>
                    <FormGroup label="Upload Certificate if Applicable">
                        <DocumentsUploader class="mb-2" v-model="edit_object.documents" role="education_history" target="Modules\Clients\Entities\ClientProfile" :target_id="user_data.profile.id"/>
                    </FormGroup>
                    <div><Btn class="btn-block btn-secondary-light mb-2" @click="saveObject">{{ edit_index !== null ? 'Save' : 'Add' }}</Btn></div>
                    <div><Btn class="btn-block btn-outline-light" @click="cancelEditObject">Cancel</Btn></div>
                </div>
            </OnboardingCard>
            <OnboardingCard
                v-else-if="edit_object && edit_type === 'certificates'"
                title="Training Certificates"
                style="width: 555px"
            >
                <div>
                    <FormInput class="mb-2" label="Certificate title" placehoder="Certificate title" v-model="edit_object.title"/>
                    <FormTextarea class="mb-2" label="Description" :count="150" placehoder="Description" v-model="edit_object.description"/>
                    <FormInput class="mb-2" label="Issued By" v-model="edit_object.issued_by"/>
                    <FormDate class="mb-2" altFormat="m/d/Y" placeholder="mm/dd/yyyy" label="From" :time="false" v-model="edit_object.period.from"/>
                    <FormDate class="mb-2" altFormat="m/d/Y" placeholder="mm/dd/yyyy" label="To" :time="false" v-model="edit_object.period.to"/>
                    <FormGroup label="Upload Training Certificate if Applicable">
                        <DocumentsUploader class="mb-2" v-model="edit_object.documents" role="certificates" target="Modules\Clients\Entities\ClientProfile" :target_id="user_data.profile.id"/>
                    </FormGroup>
                    <div><Btn class="btn-block btn-secondary-light mb-2" @click="saveObject">{{ edit_index !== null ? 'Save' : 'Add' }}</Btn></div>
                    <div><Btn class="btn-block btn-outline-light" @click="cancelEditObject">Cancel</Btn></div>
                </div>
            </OnboardingCard>
            <OnboardingCard
                v-else-if="edit_object && edit_type === 'publications'"
                title="Publications"
                subtitle="You can simply connect with your ORCID account to import your publications or fill it manually"
                style="width: 555px"
            >
                <div>
                    <button @click="AuthProvider('orcid')" class="btn btn-outline-light mb-3 btn-block ml-0">
                        <ORCIDIcon style="margin-right: 4px"/>
                        Connect with ORCID account
                    </button>

                    <div class="word-hr mb-3"><span>or fill manually</span></div>

                    <FormInput class="mb-2" label="Publication Title" placehoder="Title" v-model="edit_object.title"/>
                    <FormTextarea class="mb-2" label="Description" :count="150" placehoder="Description" v-model="edit_object.description"/>
                    <FormGroup label="Author(s)">
                        <TagSelect v-model="edit_object.authors"/>
                    </FormGroup>
                    <FormInput class="mb-2" label="Year of publication" v-model="edit_object.year"/>
                    <FormGroup label="Volume and Issue Number">
                        <div class="row">
                            <div class="col-md-6"><FormInput class="mb-2" placeholder="Volume Number" v-model="edit_object.volume_number"/></div>
                            <div class="col-md-6"><FormInput class="mb-2" placeholder="Issue Number" v-model="edit_object.issue_number"/></div>
                        </div>
                    </FormGroup>
                    <div><Btn class="btn-block btn-secondary-light mb-2" @click="saveObject">{{ edit_index !== null ? 'Save' : 'Add' }}</Btn></div>
                    <div><Btn class="btn-block btn-outline-light" @click="cancelEditObject">Cancel</Btn></div>
                </div>
            </OnboardingCard>
            <OnboardingCard
                v-else-if="edit_object && edit_type === 'awards'"
                title="Awards"
                style="width: 555px"
            >
                <div>
                    <FormInput class="mb-2" label="Award Title" placehoder="Title" v-model="edit_object.title"/>
                    <FormTextarea class="mb-2" label="Description" :count="150" placehoder="Description" v-model="edit_object.description"/>
                    <FormInput class="mb-2" label="Awarded by" placehoder="" v-model="edit_object.awarded_by"/>
                    <FormDate class="mb-2" altFormat="m/d/Y" placeholder="mm/dd/yyyy" label="Date" :time="false" v-model="edit_object.date"/>
                    <FormGroup label="Upload Award Certificate if Applicable">
                        <DocumentsUploader class="mb-2" v-model="edit_object.documents" role="awards" target="Modules\Clients\Entities\ClientProfile" :target_id="user_data.profile.id"/>
                    </FormGroup>
                    <div><Btn class="btn-block btn-secondary-light mb-2" @click="saveObject">{{ edit_index !== null ? 'Save' : 'Add' }}</Btn></div>
                    <div><Btn class="btn-block btn-outline-light" @click="cancelEditObject">Cancel</Btn></div>
                </div>
            </OnboardingCard>
            <Steps :index="step" v-else @stepsChange="stepsChange">
                <Step name="PrevExperience">
                    <OnboardingCard
                        title="Have You Worked as a Biotech Freelancer Before?"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormRadio :bordered="true" fieldValue="1" name="prev_experience" v-model="user_data.prev_experience" text="Yes"/>
                            <FormRadio :bordered="true" fieldValue="0" name="prev_experience" v-model="user_data.prev_experience" text="No"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Profile">
                    <OnboardingCard
                        title="Fill your profile"
                        subtitle="You can fill your profile automatically from your LinkedIn account or fill it manually"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <div>
                                <Btn class="btn-outline-light btn-block mb-2"><LinkedInIcon height="30" width="30"/> Sign in With LinkedIn</Btn>
                            </div>
                            <div>
                                <Btn class="btn-outline-light btn-block mb-2" @click="goToNextStep"><EditIcon height="30" width="30"/> Fill it Manually</Btn>
                            </div>
                        </div>
                        <div class="mx-auto" style="max-width: 700px">
                            <div class="alert-outline mt-4">
                                You should upload copies of your educational credentials, certificates, proof of work history, and government ID which will only be visible to BOLG admins so that they can verify you within 24-48 hours.
                            </div>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="ProfilePhoto">
                    <OnboardingCard
                        title="Upload profile photo"
                        subtitle="Drag the photo to move and perfectly fit"
                        style="width: 508px;"
                    >
                        <div style="display: flex; align-items: center; flex-direction: column;">
                            <div style="position: relative;">
                                <ImagesUploaderCrop :size="320" :value="user_data.image" @input="(value) => user_data.image = value" :multiple="false"/>
                                <SwirleIcon class="l-auth__onboarding_crop-icon"/>
                            </div>
                        </div>
        <!--                <Btn class="btn-block btn-secondary-light" @click="user_data.image = null">Save Photo</Btn>-->
                    </OnboardingCard>
                </Step>
                <Step name="Company">
                    <OnboardingCard
                        title="Fill Your Company Profile if You Are Registering a Business Account"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormInput class="mb-2" label="Company Name" placehoder="" :value="user_data.company.name" @input="(value) => user_data.company.name = value"/>
                            <FormInput class="mb-2" label="Website" placehoder="" :value="user_data.company.website" @input="(value) => user_data.company.website = value"/>
                            <FormInput class="mb-2" label="Street Address" placehoder="" :value="user_data.company.address.address" @input="(value) => user_data.company.address.address = value"/>
                            <FormInput class="mb-2" label="City" placehoder="" :value="user_data.company.address.city_name" @input="(value) => user_data.company.address.city_name = value"/>
                            <FormInput class="mb-2" label="Province" placehoder="" :value="user_data.company.address.county_name" @input="(value) => user_data.company.address.county_name = value"/>
                            <FormInput class="mb-2" label="ZIP code" placehoder="" :value="user_data.company.address.postcode" @input="(value) => user_data.company.address.postcode = value"/>
                            <FormGroup label="Country">
                                <CountrySelect :value="user_data.company.address.country" @input="(value) => user_data.company.address.country = value"/>
                            </FormGroup>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="companyInfo" key="companyInfo" v-if="user_data.company.name">
                    <OnboardingCard
                        title="Number of Employees"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="max-width: 380px">
                            <FormRadio :bordered="true" fieldValue="<15" name="employees" :value="user_data.company.employees" @input="(value) => user_data.company.employees = value" text="Less Than 15 Employees"/>
                            <FormRadio :bordered="true" fieldValue="15-50" name="employees" :value="user_data.company.employees" @input="(value) => user_data.company.employees = value" text="Between 15-50"/>
                            <FormRadio :bordered="true" fieldValue="50-100" name="employees" :value="user_data.company.employees" @input="(value) => user_data.company.employees = value" text="50-100 Employees"/>
                            <FormRadio :bordered="true" fieldValue="100-500" name="employees" :value="user_data.company.employees" @input="(value) => user_data.company.employees = value" text="100-500 Employees"/>
                            <FormRadio :bordered="true" fieldValue=">500" name="employees" :value="user_data.company.employees" @input="(value) => user_data.company.employees = value" text="More than 500 Employees"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Additionalinfo" key="Additionalinfo" v-if="user_data.company.name">
                    <OnboardingCard
                        title="Additional information"
                        style="width: 735px"
                    >
                        <div class="mx-auto" style="max-width: 410px">
                            <FormGroup label="The company Logo" class="text-center">
                                <ImagesUploaderCrop class="mt-3" :size="200" :value="user_data.company.image" @input="(value) => user_data.company.image = value" :multiple="false"/>
                            </FormGroup>
                            <FormInput class="mb-2" label="Your Position in the Company" placehoder="e.g. Reasearcher" :value="user_data.company.position" @input="(value) => user_data.company.position = value"/>
                            <FormDate :time="false" altFormat="m/d/Y" placeholder="mm/dd/yyyy" class="mb-2" label="Established Date" :value="user_data.company.established_date" @input="(value) => user_data.company.established_date = value"/>
                            <FormGroup label="Upload a Proof of Business">
                                <DocumentsUploader class="mb-2" :value="user_data.company.documents" @input="(value) => user_data.company.documents = value"/>
                            </FormGroup>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="EnglishLevel" key="EnglishLevel">
                    <OnboardingCard
                        title="English Level"
                        subtitle="Also you can add other language(s)"
                        style="width: 735px"
                    >
                        <div class="mx-auto" style="max-width: 410px">
                            <FormRadio :bordered="true" v-for="option in attributes.english_language_level"
                                       :fieldValue="option.id"
                                       :key="option.id"
                                       name="english_language_level"
                                       v-model="user_data.attributes.english_language_level"
                                       :text="option.name"/>

                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Education" key="Education">
                    <OnboardingCard
                        title="Highest Education Achieved"
                        style="width: 735px"
                    >
                        <div class="mx-auto" style="max-width: 410px">
                            <FormRadio :bordered="true" v-for="option in attributes.education"
                                       :fieldValue="option.id"
                                       :key="option.id"
                                       name="education"
                                       v-model="user_data.attributes.education"
                                       :text="option.name"/>

                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="ColorCodeCategory" key="ColorCodeCategory">
                    <OnboardingCard
                        title="Color Code Category(s)"
                        style="width: 710px"
                    >
                        <div class="text-center">
                            <div class="mx-auto" style="display: inline-block">
                                <FormCheckbox v-for="option in attributes.color_code_categories"
                                           :fieldValue="option.id"
                                           :key="option.id"
                                           :id="'color_code_categories' + option.id"
                                           name="color_code_categories"
                                           v-model="user_data.attributes.color_code_categories"
                                >
                                    <template v-slot:labelslot>
                                        <div class="o-attribute-option" >
                                            <div class="o-attribute-option__icon" v-if="option.icon">
                                                <XImgset class="img-fluid" :src="option.icon" :width="20" :height="20" :alt="option.name"/>
                                            </div>
                                            <div>
                                                <div class="o-attribute-option__title">{{ option.name }}</div>
                                                <div class="o-attribute-option__desc" v-if="option.subtitle">{{ option.subtitle }}</div>
                                            </div>
                                        </div>
                                    </template>
                                </FormCheckbox>

                            </div>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="service" key="service">
                    <OnboardingCard
                        title="Determine your service(s)"
                        style="width: 735px"
                    >
                        <div class="mx-auto" style="max-width: 410px">
                            <div class="row">
                                <div class="col-md-6" v-for="option in attributes.services">
                                    <FormCheckbox
                                            :id="'services' + option.id"
                                            :fieldValue="option.id"
                                            :key="option.id"
                                            name="services"
                                            size="lg"
                                            v-model="user_data.attributes.services"
                                            :text="option.name"/>
                                </div>
                            </div>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="ExpertiseLevel" key="ExpertiseLevel">
                    <OnboardingCard
                            title="Your expertise level"
                            style="width: 735px"
                    >
                        <div class="mx-auto" style="max-width: 410px">
                            <FormRadio :bordered="true" v-for="option in attributes.expertise_level"
                                       :fieldValue="option.id"
                                       :key="option.id"
                                       name="expertise_level"
                                       v-model="user_data.attributes.expertise_level"
                                       :text="option.name"/>

                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="ExpertiseAreas" key="ExpertiseAreas">
                    <OnboardingCard
                            title="Choose your expertise area(s)"
                            subtitle="You can choose up to 20 expertise areas"
                            style="width: 735px"
                    >
                        <div class="mx-auto" style="max-width: 410px">
                            <div class="pb-2">
                                <div class="badge badge-primary-light badge-lg mr-1 mb-1" v-for="(expertise_area, index) in user_data.attributes.expertise_areas">{{ expertise_area.name }} <a @click.prevent="() => user_data.attributes.expertise_areas.splice(index, 1)">X</a></div>
                            </div>
                            <AttributeSelect attribute_code="expertise_areas" class="mb-1" :searchOnly="true" @select="(option) => user_data.attributes.expertise_areas.push(option)"/>

<!--                            <div class="text-center">-->
<!--                                <Btn class="" @click="$modal.show('addExpertiseArea')"> <span class="btn btn-primary btn-round mr-2">+</span> Add other</Btn>-->
<!--                            </div>-->

<!--                            <modal name="addExpertiseArea" height="auto" :scrollable="true" style="border-radius: 10px">-->
<!--                                <div class="modal-dialog">-->
<!--                                    <div class="modal-content">-->
<!--                                        <a style="cursor: pointer" class="close" @click="$modal.hide('addExpertiseArea')">-->
<!--                                            <CloseIcon class="close"/>-->
<!--                                        </a>-->
<!--                                        <div class="modal-header">-->
<!--                                            <h4 class="modal-title">Add new expertise Area</h4>-->
<!--                                        </div>-->
<!--                                        <div class="modal-body">-->
<!--                                            <FormInput class="mb-2" label="Expertise Area" v-model="newExpertiseArea"/>-->
<!--                                        </div>-->
<!--                                        <div class="modal-footer">-->
<!--                                            <Btn class="btn btn-lg btn-secondary btn-block" @click="addExpertiseArea()">Save</Btn>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </modal>-->
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="HourlyRate" key="HourlyRate">
                    <OnboardingCard
                            title="Please set up your hourly rate"
                            subtitle="This will help us to connect you with proper clients"
                            style="width: 780px"
                    >
                        <div>
                            <label class="mb-5" for="">Hourly Rate:  <span class="text-primary">{{ user_data.hourly_rate | toPrice }}</span></label>
                            <ClientOnly>
                                <vue-slider v-model="user_data.hourly_rate"
                                            :min="0"
                                            :max="400"
                                            :dotSize="28"
                                            :height="2"
                                            :tooltip="'none'"
                                ></vue-slider>
                            </ClientOnly>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Availability" key="Availability">
                    <OnboardingCard
                            title="Availability"
                            style="width: 735px"
                    >
                        <div class="mx-auto" style="max-width: 410px">
                            <FormRadio :bordered="true" v-for="option in attributes.availability"
                                       :fieldValue="option.id"
                                       :key="option.id"
                                       name="availability"
                                       v-model="user_data.attributes.availability"
                                       :text="option.name"/>

                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="consultation" key="consultation">
                    <OnboardingCard
                            title="Offering Consultation Session"
                            subtitle="You will have a calendar which will be integrated with Zoom"
                            style="width: 735px"
                    >
                        <div class="mx-auto" style="max-width: 410px">
                            <FormRadio :bordered="true" fieldValue="1" name="consultation" v-model="user_data.attributes.consultation" text="Yes"/>
                            <FormRadio :bordered="true" fieldValue="0" name="consultation" v-model="user_data.attributes.consultation" text="No"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Description" key="Description">
                    <OnboardingCard
                            title="Describe yourself by a few words"
                            style="width: 735px"
                    >
                        <div class="mx-auto">
                            <FormTextarea v-model="user_data.profile.description" :count="50"/>

                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Biography" key="Biography">
                    <OnboardingCard
                            title="Your Biography"
                            style="width: 735px"
                    >
                        <div class="mx-auto">
                            <FormTextarea v-model="user_data.profile.biography" :count="500"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="EmploymentHistory" key="EmploymentHistory">
                    <OnboardingCard
                            title="Employment History"
                            style="width: 1008px"
                    >
                        <div class="mx-auto">
                            <div class="c-onboarding-profile-item" v-for="(employment, index) in user_data.profile.employment_history">
                                <div class="c-onboarding-profile-item__type"><EmploymentIcon fill="#09CD61" height="24" width="24"/></div>
                                <div class="c-onboarding-profile-item__body">
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Job Title</div>
                                        <div class="c-onboarding-profile-item__value">{{ employment.name }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Job Type</div>
                                        <div class="c-onboarding-profile-item__value"><div class="badge badge-primary">{{ employment.type }}</div></div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Company</div>
                                        <div class="c-onboarding-profile-item__value">{{ employment.company }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Time Period</div>
                                        <div class="c-onboarding-profile-item__value">{{ employment.period.from | moment('MMM YYYY') }} - <template v-if="employment.period.to">{{ employment.period.to | moment('MMM YYYY') }}</template><template v-else>Present</template></div>
                                    </div>
                                </div>
                                <div class="c-onboarding-profile-item__actions">
                                    <a href="" class="mr-2" @click.prevent="editObject('employment_history', employment, index)"><EditSimpleIcon height="20" width="20"/></a>
                                    <a href="" @click.prevent="removeObject('employment_history', index)"><Close2Icon height="20" width="20"/></a>
                                </div>
                            </div>

                            <div class="text-center mt-4">
                                <Btn class="" @click="addObject('employment_history')"> <span class="btn btn-primary btn-round mr-2">+</span> {{ user_data.profile.employment_history?.length ? 'Add more' : 'Add' }}</Btn>
                            </div>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="EducationHistory" key="EducationHistory">
                    <OnboardingCard
                        title="Education History"
                        subtitle="Enter details for each of your completed college and university degree starting from more recent degree."
                            style="width: 1008px"
                    >
                        <div class="mx-auto">
                            <div class="c-onboarding-profile-item" v-for="(education, index) in user_data.profile.education_history">
                                <div class="c-onboarding-profile-item__type"><EducationIcon fill="#09CD61" height="24" width="24"/></div>
                                <div class="c-onboarding-profile-item__body">
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Achieved Level</div>
                                        <div class="c-onboarding-profile-item__value">{{ education.level === 'Other' ? education.level_other : education.level }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Description</div>
                                        <div class="c-onboarding-profile-item__value">{{ education.description }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Institution name</div>
                                        <div class="c-onboarding-profile-item__value">{{ education.institute }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">City & Country</div>
                                        <div class="c-onboarding-profile-item__value">{{ education.city_name }} {{ education.country?.name }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Time Period</div>
                                        <div class="c-onboarding-profile-item__value">{{ education.period.from | moment('MMM YYYY') }} - <template v-if="education.period.to">{{ education.period.to | moment('MMM YYYY') }}</template><template v-else>Present</template></div>
                                    </div>
                                </div>
                                <div class="c-onboarding-profile-item__actions">
                                    <a href="" class="mr-2" @click.prevent="editObject('education_history', education, index)"><EditSimpleIcon height="20" width="20"/></a>
                                    <a href="" @click.prevent="removeObject('education_history', index)"><Close2Icon height="20" width="20"/></a>
                                </div>
                            </div>

                            <div class="text-center mt-4">
                                <Btn class="" @click="addObject('education_history')"> <span class="btn btn-primary btn-round mr-2">+</span> {{ user_data.profile.education_history?.length ? 'Add more' : 'Add' }}</Btn>
                            </div>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Skills" key="Skills">
                    <OnboardingCard
                        title="Your Skills & Experience"
                            style="width: 1168px"
                    >
                        <div class="mx-auto">
                            <FormTextarea v-model="user_data.profile.skills" placeholder="Description" :count="500"/>
                            <DocumentsUploader  v-model="user_data.profile.skill_documents"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="TrainingCertificates" key="TrainingCertificates">
                    <OnboardingCard
                        title="Training Certificates"
                            style="width: 1168px"
                    >
                        <div class="mx-auto">
                            <div class="c-onboarding-profile-item" v-for="(certificate, index) in user_data.profile.certificates">
                                <div class="c-onboarding-profile-item__type"><EmploymentIcon fill="#09CD61" height="24" width="24"/></div>
                                <div class="c-onboarding-profile-item__body">
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Certificate title</div>
                                        <div class="c-onboarding-profile-item__value">{{ certificate.title }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Description</div>
                                        <div class="c-onboarding-profile-item__value">{{ certificate.description }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Issued By</div>
                                        <div class="c-onboarding-profile-item__value">{{ certificate.issued_by }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Training Period</div>
                                        <div class="c-onboarding-profile-item__value">{{ certificate.period.from | moment('MMM YYYY') }} - <template v-if="certificate.period.to">{{ certificate.period.to | moment('MMM YYYY') }}</template><template v-else>Present</template></div>
                                    </div>
                                </div>
                                <div class="c-onboarding-profile-item__actions">
                                    <a href="" class="mr-2" @click.prevent="editObject('certificates', certificate, index)"><EditSimpleIcon height="20" width="20"/></a>
                                    <a href="" @click.prevent="removeObject('certificates', index)"><Close2Icon height="20" width="20"/></a>
                                </div>
                            </div>

                            <div class="text-center mt-4">
                                <Btn class="" @click="addObject('certificates')"> <span class="btn btn-primary btn-round mr-2">+</span> {{ user_data.profile.certificates?.length ? 'Add more' : 'Add' }}</Btn>
                            </div>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Publications" key="Publications">
                    <OnboardingCard
                        title="Publications"
                        subtitle="Here you can find your publications. You can add many publications."
                        style="width: 1168px"
                    >
                        <div class="mx-auto">
                            <div class="c-onboarding-profile-item" v-for="(publication, index) in user_data.profile.publications">
                                <div class="c-onboarding-profile-item__type"><PublicationIcon fill="#09CD61" height="24" width="24"/></div>
                                <div class="c-onboarding-profile-item__body">
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Publication Title</div>
                                        <div class="c-onboarding-profile-item__value">{{ publication.title }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Author(s)</div>
                                        <div class="c-onboarding-profile-item__value">{{ publication.authors.join(', ') }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Year of publication</div>
                                        <div class="c-onboarding-profile-item__value">{{ publication.year }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Volume and Issue Number</div>
                                        <div class="c-onboarding-profile-item__value">{{ publication.volume_number }}, {{ publication.issue_number }}</div>
                                    </div>
                                </div>
                                <div class="c-onboarding-profile-item__actions">
                                    <a href="" class="mr-2" @click.prevent="editObject('publications', publication, index)"><EditSimpleIcon height="20" width="20"/></a>
                                    <a href="" @click.prevent="removeObject('publications', index)"><Close2Icon height="20" width="20"/></a>
                                </div>
                            </div>

                            <div class="text-center mt-4">
                                <Btn class="" @click="addObject('publications')"> <span class="btn btn-primary btn-round mr-2">+</span> {{ user_data.profile.publications?.length ? 'Add more' : 'Add' }}</Btn>
                            </div>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Awards" key="Awards">
                    <OnboardingCard
                        title="Awards"
                        style="width: 1168px"
                    >
                        <div class="mx-auto">
                            <div class="c-onboarding-profile-item" v-for="(award, index) in user_data.profile.awards">
                                <div class="c-onboarding-profile-item__type"><EmploymentIcon fill="#09CD61" height="24" width="24"/></div>
                                <div class="c-onboarding-profile-item__body">
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Award Title</div>
                                        <div class="c-onboarding-profile-item__value">{{ award.title }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Description</div>
                                        <div class="c-onboarding-profile-item__value">{{ award.description }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Awarded By</div>
                                        <div class="c-onboarding-profile-item__value">{{ award.awarded_by }}</div>
                                    </div>
                                    <div>
                                        <div class="c-onboarding-profile-item__label">Date</div>
                                        <div class="c-onboarding-profile-item__value">{{ award.date | moment('MMM YYYY') }}</div>
                                    </div>
                                </div>
                                <div class="c-onboarding-profile-item__actions">
                                    <a href="" class="mr-2" @click.prevent="editObject('awards', award, index)"><EditSimpleIcon height="20" width="20"/></a>
                                    <a href="" @click.prevent="removeObject('awards', index)"><Close2Icon height="20" width="20"/></a>
                                </div>
                            </div>

                            <div class="text-center mt-4">
                                <Btn class="" @click="addObject('awards')"> <span class="btn btn-primary btn-round mr-2">+</span> {{ user_data.profile.publications?.length ? 'Add more' : 'Add' }}</Btn>
                            </div>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="JoinReason" key="JoinReason">
                    <OnboardingCard
                        title="Why Do you want to join BOLG?"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="width: 488px; max-width: 100%">
                            <div class="o-privacy-select mb-2" :class="{'active': user_data.join_reason === 'main_income'}" @click="user_data.join_reason = 'main_income'">
                                <div class="o-privacy-select__icon"><BubbleWalletIcon/></div>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">To earn main income</div>
                                </div>
                            </div>
                            <div class="o-privacy-select mb-2" :class="{'active': user_data.join_reason === 'side_income'}" @click="user_data.join_reason = 'side_income'">

                                <div class="o-privacy-select__icon"><BubbleDollarIcon/></div>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">To earn side income</div>
                                </div>
                            </div>
                            <div class="o-privacy-select mb-2" :class="{'active': user_data.join_reason === 'highlight'}" @click="user_data.join_reason = 'highlight'">

                                <div class="o-privacy-select__icon"><BubbleEmploymentIcon/></div>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">To highlight my skills so that I can get a job in the future</div>
                                </div>
                            </div>
                            <div class="o-privacy-select mb-2" :class="{'active': user_data.join_reason === 'other'}" @click="user_data.join_reason = 'other'">
                                <div class="o-privacy-select__icon"><BubbleEditIcon/></div>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">Other reason(s)</div>
                                </div>
                            </div>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="GovermentId" key="GovermentId">
                    <OnboardingCard
                        title="Upload Government ID"
                        style="width: 932px"
                    >
                        <div>
                            <DocumentsUploader class="mb-2" v-model="user_data.profile.government_ids" role="government_id" target="Modules\Clients\Entities\ClientProfile" :target_id="user_data.profile.id"/>
                        </div>
                    </OnboardingCard>
                </Step>
                <Step name="Visibility" key="Visibility">
                    <OnboardingCard
                        title="Profile Visibility"
                        subtitle="Who do you want to see your profile? Simply, select an option to control your visibiity. You can change this later in your profile data"
                        style="width: 710px"
                    >
                        <div class="mx-auto" style="width: 488px; max-width: 100%;">
                            <div class="o-privacy-select mb-2" :class="{'active': user_data.privacy === 'public'}" @click="user_data.privacy = 'public'">
                                <div class="o-privacy-select__icon"><PrivacyPublicIcon/></div>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">Public</div>
                                    <div class="o-privacy-select__info-description">Anyone can see it, it will be indexed in search engines</div>
                                </div>
                            </div>
                            <div class="o-privacy-select mb-2" :class="{'active': user_data.privacy === 'registered'}" @click="user_data.privacy = 'registered'">
                                <div class="o-privacy-select__icon"><PrivacyRegisteredIcon/></div>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">BOLG Users only</div>
                                    <div class="o-privacy-select__info-description">Only users logged in to BOLG can find it</div>
                                </div>
                            </div>
                            <div class="o-privacy-select mb-2" :class="{'active': user_data.privacy === 'private'}" @click="user_data.privacy = 'private'">
                                <div class="o-privacy-select__icon"><PrivacyPrivateIcon/></div>
                                <div class="o-privacy-select__info">
                                    <div class="o-privacy-select__info-title">Private*</div>
                                    <div class="o-privacy-select__info-description">Only BOLG admins can see it.</div>
                                </div>
                            </div>
                        </div>
                    </OnboardingCard>
                </Step>

            </Steps>
        </div>
        <div class="l-auth__footer" v-if="!finished">
            <div class="container l-auth__footer-container" v-if="preview">
                <Btn class="btn-outline-light --w280" @click="preview = false">Edit Profile</Btn>
                <Btn class="btn-secondary --w280" @click="saveUserData" :loading="loading.save">Create Profile</Btn>
            </div>
            <template v-else-if="!edit_object">
                <div class="l-auth__footer-progress d-flex d-md-none">
                    <div class="progress mr-2" style="width: 55px">
                        <div class="progress-bar" role="progressbar" :style="{width: completed + '%'}"></div>
                    </div>
                    {{ completed }}% Profile Completed
                </div>
                <div class="container l-auth__footer-container">
                    <Btn class="btn-outline-light --w120" :disabled="step == 0" @click="goToPrevStep">Back</Btn>
                    <div class="l-auth__footer-progress d-none d-md-flex">
                        <div class="progress mr-2" style="width: 55px">
                            <div class="progress-bar" role="progressbar" :style="{width: completed + '%'}"></div>
                        </div>
                        {{ completed }}% Profile Completed
                    </div>
                    <div style="white-space: nowrap">
                        <Btn class="text-secondary" v-if="step < stepsCount - 1" @click="skip">Skip for now</Btn>
                        <Btn class="btn-outline-light --w120" v-else @click="preview = true">Preview</Btn>
                        <Btn class="btn-secondary --w120" v-if="step < stepsCount - 1" @click="goToNextStep">Next</Btn>
                        <Btn class="btn-secondary --w120" v-else @click="saveUserData" :loading="loading.save">Submit</Btn>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>


import { mapState } from 'vuex'
import Btn from "~/components/Btn.vue";
import FormInput from "~/components/FormInput.vue";
import CountrySelect from "~/components/CountrySelect.vue";
import Bubbles from "~/assets/assets/bubbles.svg?inline";
import OnboardingCard from "~/components/OnboardingCard.vue";
import BadgeSelect from "~/components/BadgeSelect.vue";
import ImagesUploaderCrop from "~/components/ImageUploaderCrop.vue";
import FormRadio from "~/components/FormRadio.vue";
import FormDate from "~/components/FormDate.vue";
import DocumentsUploader from "~/components/DocumentsUploader.vue";
import LinkedInIcon from "~/assets/icons/auth/linkedin.svg?inline";
import CloseIcon from "~/assets/icons/close-circle.svg?inline";
import EditSimpleIcon from "~/assets/icons/edit-simple.svg?inline";
import Close2Icon from "~/assets/icons/close-square.svg?inline";

import ORCIDIcon from "~/assets/icons/auth/orcid.svg?inline";
import WalletIcon from "~/assets/icons/wallet.svg?inline";

import BubbleWalletIcon from "~/assets/icons/bubble_wallet.svg?inline";
import BubbleDollarIcon from "~/assets/icons/bubble_dollar.svg?inline";
import BubbleEmploymentIcon from "~/assets/icons/bubble_employment.svg?inline";
import BubbleEditIcon from "~/assets/icons/bubble_edit.svg?inline";

import SwirleIcon from "~/assets/icons/swirle.svg?inline";
import Steps from "~/components/Steps.vue";
import Step from "~/components/Step.vue";
import FormCheckbox from "~/components/FormCheckbox.vue";
import XImgset from "~/components/XImgSet.vue";
import AttributeSelect from "~/components/AttributeSelect.vue";
import FormTextarea from "~/components/FormTextarea.vue";
import Multiselect from "vue-multiselect";
import TagSelect from "~/components/TagSelect.vue";
import FreelancerDetails from "../Freelancers/FreelancerDetails.vue";


import {profile_objects} from '~/utils/consts.js'

export default {
    name: 'AuthFreelancerOnboarding',
    components: {
        FreelancerDetails,
        TagSelect,
        Multiselect,
        FormTextarea,
        AttributeSelect,
        XImgset,
        FormCheckbox,
        Step,
        Steps, DocumentsUploader, FormDate, FormRadio, CountrySelect,
        FormInput, ImagesUploaderCrop, BadgeSelect, OnboardingCard, Btn,
        Bubbles,
        SwirleIcon, LinkedInIcon, CloseIcon, EditSimpleIcon, Close2Icon,
        ORCIDIcon,
        WalletIcon,BubbleWalletIcon,BubbleDollarIcon,BubbleEmploymentIcon,BubbleEditIcon
    },
    data() {
        return {
            loading: {
                data: false,
                save: false,
            },
            preview: false,
            stepsCount: 0,
            step: 0,
            finished: false,
            user_data: {
                prefix: "Mrs.",
                image: null,
                company: {
                    name: '',
                    description: '',
                    website: '',
                    employees: null,
                    image: null,
                    position:null,
                    established_date:null,
                    address: {
                        address: '',
                        city_name: '',
                        county_name: '',
                        postcode: '',
                        country: null,
                    },
                    documents: [],
                },
                address: {
                    address: '',
                    city_name: '',
                    county_name: '',
                    postcode: '',
                    country: null,
                },
                hourly_rate: 0,
                prev_experience: null,
                attributes: {
                    english_language_level: null,
                    education: null,
                    color_code_categories: [],
                    services: [],
                    expertise_level: null,
                    expertise_areas: [],
                    availability: null,
                    consultation: false,
                },
                profile: {
                    description: '',
                    biography: '',
                    employment_history: [],
                    education_history: [],
                    skills: '',
                    skill_documents: [],
                    certificates: [],
                    publications: [],
                    awards: [],
                    government_ids: [],
                },
                join_reason: null,
            },
            attributes: {},
            privacy: null,
            newExpertiseArea: '',
            edit_type: null,
            edit_index: null,
            edit_object: null,
        }
    },
    validations: {

    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
        completed() {
            return (this.step / (this.stepsCount-1) * 100).toFixed(0);
        },
    },
    created: function () {
        this.getData()
    },
    methods: {
        goToPrevStep() {
            if (this.loading.data) {
                return;
            }
            if (this.edit_object) {
                this.edit_index = null;
                this.edit_type = null;
                this.edit_object = null;
                return;
            }
            if (this.step > 0) {
                this.step--;
            }
        },
        goToNextStep() {
            if (this.loading.data) {
                return;
            }
            if (this.edit_object) {
                return;
            }
            if (this.step <= this.stepsCount) {
                this.step++;
            }
        },
        skip() {
            this.$router.push({'name': 'account.dashboard'})
        },
        async getData() {
            this.loading.data = true;
            await this.$axios.post("/api/get-attributes-options", {
                attribute_code: ['english_language_level', 'education', 'color_code_categories', 'services', 'expertise_level', 'expertise_areas', 'availability']
            })
                .then((res) =>{
                    this.attributes = res;
                })

            await this.$axios.get("/account/get-info")
                .then((res) =>{
                    this.user_data = {...this.user_data, ...res.data}
                    if (!this.user.company) {
                        this.user_data.company = {
                            name: '',
                            description: '',
                            website: '',
                            employees: null,
                            image: null,
                            position:null,
                            established_date:null,
                            address: {
                                address: '',
                                city_name: '',
                                county_name: '',
                                postcode: '',
                                country: null,
                            },
                            documents: [],
                        }
                    }
                });
            this.loading.data = false;
        },
        saveUserData() {
            this.loading.save = true;
            this.$axios.post("/account/save-onboarding", this.user_data)
                .then((res) =>{
                    this.loading.save = false;
                    this.finished = true;
                    this.preview = false;
                })
                .catch((res) =>{
                    this.loading.save = false;
                })
        },
        stepsChange(count) {
            console.log({count})
            this.stepsCount = count
        },
        addExpertiseArea() {
            this.user_data.attributes.expertise_areas.push({
                id: null,
                attribute_id: null,
                attribute_code: 'expertise_areas',
                name: this.newExpertiseArea
            })
            this.$modal.hide('addExpertiseArea')
            this.newExpertiseArea = '';
        },
        addObject(type) {
            this.edit_index = null
            this.edit_type = type
            this.edit_object = JSON.parse(JSON.stringify(profile_objects[type]))
        },
        editObject(type, object, index) {
            this.edit_index = index
            this.edit_type = type
            this.edit_object = {...profile_objects[type], ...JSON.parse(JSON.stringify(object))}
        },
        cancelEditObject() {
            this.edit_index = null
            this.edit_type = null
            this.edit_object = null
        },
        saveObject() {
            if(this.edit_index === null) {
                if (!this.user_data.profile[this.edit_type]) {
                    this.user_data.profile[this.edit_type] = [];
                }
                this.user_data.profile[this.edit_type].push(JSON.parse(JSON.stringify(this.edit_object)));
            }else{
                this.user_data.profile[this.edit_type][this.edit_index] = JSON.parse(JSON.stringify(this.edit_object))
            }

            this.edit_index = null
            this.edit_type = null
            this.edit_object = null
        },
        removeObject(type, index) {
            this.$dialog.confirm('Are you sure?', {
                okText: 'Yes',
                cancelText: 'No'
            })
                .then(() => {
                    console.log(this.user_data.profile[type])
                    this.user_data.profile[type].splice(index, 1);
                }).catch(e => {
                    console.log(e)
            });
        },
    },
}
</script>
