<template>
    <div class="mb-4" :class="{'loading': loading.data}">
        <ClientOnly>
            <modal name="filtersModal" height="auto" width="100%" :scrollable="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <a style="cursor: pointer" class="close" @click="$modal.hide('filtersModal')">
                            <CloseIcon class="close"/>
                        </a>
                        <div class="l-items-grid__filters-title">
                            Filter By
                        </div>
                        <GridFilters :loading="loading.filters"
                                     :filters="filters"
                                     @setFilterValue="(payload) => $emit('setFilterValue', payload)"
                                     @clearFilterValue="(payload) => $emit('clearFilterValue', payload)"
                        />
                    </div>
                </div>
            </modal>
        </ClientOnly>
        <div class="l-items-grid" id="itemsGrid">
            <div class="l-items-grid__sidebar d-none d-lg-block" id="sidebar" v-if="filters.length">
                <div class="l-items-grid__filters-title">
                    Filter By
                </div>
                <div class="l-items-filters">
                    <GridFilters :loading="loading.filters"
                                 :filters="filters"
                                 @setFilterValue="(payload) => $emit('setFilterValue', payload)"
                                 @clearFilterValue="(payload) => $emit('clearFilterValue', payload)"
                    />
                </div>
            </div>
            <div class="l-items-grid__content" v-if="data">
                <div class="l-items-grid-page-header" v-if="data.data.length">
                    <div class="l-items-grid-page-header__left">
                        <div class="l-items-grid__title">
                            {{ $tc(`search.${resultsString}.results`, data.data.length, { n: data.data.length }) }}
                        </div>
                    </div>
                    <div class="l-items-grid-page-header__right d-none d-lg-block">
                        <SortingDropdown/>
                    </div>
                </div>
                <div class="l-items-grid-page-header" v-else>
                    <div class="l-items-grid-page-header__left">
                        <div class="l-items-grid__title">
                            {{ $t(`search.${resultsString}.no_results`) }}
                        </div>
                    </div>
                </div>
                <div class="mb-2 d-flex justify-content-between d-lg-none">
                    <div>
                        <SortingDropdown/>
                    </div>
                    <div v-if="filters.length">
                        <a class="o-btn-dropdown" @click.prevent="toggleFilters"><span>Filters</span> <span class="badge badge-primary"></span> <Filters2 height="20"/></a>
                    </div>
                </div>
                <div v-show-slide="sortingExpanded">
                    <SortingDropdownMobile class="d-md-none" @onChange="toggleSorting(false)"/>
                </div>
                <div class="l-items-grid__items">
                    <div class="mb-2" v-for="item in data.data">
                        <slot name="item" :item="item"></slot>
                    </div>
                </div>
                <div class="text-xs-center">
                    <pagination :limit="10" :data="data" v-on:pagination-change-page="changePage"/>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapState } from 'vuex'
import SortingDropdown from "./SortingDropdown.vue";
import Shimmer from "./Shimmer.vue";
import SortingDropdownMobile from "./SortingDropdownMobile.vue";
import GridFilters from "./GridFilters.vue";
import FreelancerCard from "../pages/Freelancers/FreelancerCard.vue";
import ShimmerFreelancerCard from "../pages/Freelancers/ShimmerFreelancerCard.vue";

export default {
    name: 'ItemsGrid',
    components: {ShimmerFreelancerCard, FreelancerCard, GridFilters, SortingDropdownMobile, Shimmer, SortingDropdown},
    props: {
        breadcrumbs: {
            type: Array,
            default: function () {return []}
        },
        data: {
            type: Object,
            default: function () {return null}
        },
        resultsString: {
            type: String,
            default: function () {return 'results'}
        },
        filters: {
            type: Array,
            default: function () {return []}
        },
        loading: {
            type: Object,
            default: function () {
                return {
                    data: false,
                    filters: false,
                }
            }
        },
        title: String,
        description: String,
    },
    data() {
        return {
            gridView: 3,
            filtersExpanded: true,
            sortingExpanded: false,
        }
    },
    mounted () {
        if(window.innerWidth < 991) {
            this.filtersExpanded = false;
            this.setGridView(this.filters.length ? 2 : 3);
        } else {
            this.filtersExpanded = true;
            this.setGridView(this.filters.length ? 3 : 4);
        }
    },
    computed: {
        ...mapState(['itemsSort']),
    },
    methods: {
        setGridView(value) {
            this.gridView = value
        },
        toggleFilters () {
            this.$modal.show('filtersModal')
            // this.filtersExpanded = !this.filtersExpanded,
            // this.sortingExpanded = false
        },
        toggleSorting (value) {
            this.sortingExpanded = value ?? !this.sortingExpanded,
            this.filtersExpanded = false
        },
        changePage (payload) {
            this.$smoothScroll({
                scrollTo: document.getElementById('itemsGrid'),
                updateHistory: false,
            })
            this.$emit('getPage', payload)
        },
        onSelect (profile) {
            this.$emit('onSelect', profile)
        }
    }
}
</script>
