<template>
    <div class="c-freelancer-card" @click="onSelect">
        <div class="d-flex align-items-center mb-2">
            <CompanyAvatar v-if="client.company" :company="client.company" style="flex: 1" :displayName="true" :subtitle="client.description"/>
            <UserAvatar v-else :user="client" style="flex: 1" :displayName="true" :subtitle="client.description"/>
            <div class="c-freelancer-card__price" v-if="client.hourly_rate">${{ client.hourly_rate }}/hr</div>
            <GroupToggle class="ml-3" foreign_model="Modules\Clients\Entities\Client" :foreign_id="client.id" v-model="client.groups" type="freelancer"/>
        </div>
        <div class="c-freelancer-card__level" v-if="client.attributes.expertise_level">
            <img :src="client.attributes.expertise_level.icon"/> {{ client.attributes.expertise_level.value }}
        </div>
        <div class="c-freelancer-card__content truncate-overflow-sm">
            {{ client.biography }}
        </div>
        <div class="c-freelancer-card__info">
            <div v-if="client.attributes.color_code_categories">
                <ColorCodeAttribute :key="attr.value" v-for="(attr, index) in client.attributes.color_code_categories.slice(0, 1)" :attr="attr"/>
                <span class="badge badge-sm badge-secondary-light px-1" v-if="client.attributes.color_code_categories.length > 1">+{{ client.attributes.color_code_categories.length - 1}}</span>
            </div>
            <div v-if="client.address">
                <div class="c-freelancer-card__info-icon">
                    <LocationIcon height="20" width="20"/>
                </div>
                <div class="c-freelancer-card__info-title">{{ [client.address.city_name, client.address.country_name].join(', ') }}</div>
            </div>
            <div>
                <div class="c-freelancer-card__info-icon">
                    <StarIcon height="16" width="16"/>
                </div>
                <div class="c-freelancer-card__info-title">{{ client.avgReview }}/5 ({{ client.countReview }})</div>
            </div>
        </div>
        <div class="c-freelancer-card__tags-wrapper" v-if="client.attributes.services">
            <span class="c-freelancer-card__tags-title">Service(s):</span>
            <ClientOnly>
                <ItemCounter class="c-freelancer-card__tags c-freelancer-card__info2-services">
                    <span :key="service.value" v-for="service in client.attributes.services">{{ service.value }}</span>
                </ItemCounter>
            </ClientOnly>
        </div>
        <div class="c-freelancer-card__info2">
            <div v-if="client.earnings">
                <div class="c-freelancer-card__info2-icon">
                    <DolarCircleIcon height="20"/>
                </div>
                <div class="c-freelancer-card__info2-title">{{ client.earnings }}</div>
            </div>
            <div v-if="client.attributes.consultation?.value">
                <div class="c-freelancer-card__info2-icon">
                    <TeamIcon height="20"/>
                </div>
                <div class="c-freelancer-card__info2-title">Offering Consultation Session</div>
            </div>
        </div>
        <div class="c-freelancer-card__tags-wrapper" v-if="client.attributes.expertise_areas">
            <span class="c-freelancer-card__tags-title">Expertise Area(s):</span>
            <ClientOnly>
                <ItemCounter class="c-freelancer-card__tags">
                    <span class="badge badge-sm badge-secondary-light" v-for="area in client.attributes.expertise_areas">{{ area.value }}</span>
                </ItemCounter>
            </ClientOnly>
        </div>
        <div class="c-freelancer-card__projects" v-if="client.projects">
            <span :key="project.id" v-for="project in client.projects">{{ project.title }}</span>
        </div>

    </div>
</template>

<script>


import UserAvatar from "../../components/UserAvatar.vue";
import GroupIcon from "../../components/GroupToggle.vue";
import ColorCodeAttribute from "../../components/ColorCodeAttribute.vue";
import GroupToggle from "@/components/GroupToggle.vue";
import CompanyAvatar from "@/components/CompanyAvatar.vue";
import ItemCounter from "@/components/ItemCounter.vue";

export default {
    name: 'FreelancerCard',
    components: {ItemCounter, CompanyAvatar, GroupToggle, ColorCodeAttribute, GroupIcon, UserAvatar},
    props: ['client'],
    methods: {
        onSelect() {
            this.$emit('onSelect', this.client)
        },
    }
}
</script>
