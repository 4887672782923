<template>
    <div>
        <div class="l-page-hero --small"
             style="background-image: url('/images/hero_bg.svg')">
            <div style="max-width: 100%">
                <div class="l-page-hero__title">Find Biotech Job</div>
                <SearchInput
                    v-model="filters.term"
                    @search="search"
                    url="/jobs/suggestions"
                    placeholder="Find Job"
                    :loading="loading.search"/>
            </div>
        </div>
        <div class="container">
            <ItemsGrid
                :loaded="loaded"
                :loading="loading"
                :data="data"
                :filters="available_filters"
                @setFilterValue="setFilterValue"
                @clearFilterValue="clearFilterValue"
                @getPage="getPage"
                resultsString="projects"
            >
                <template #item="{item}">
                    <JobCard @onSelect="onSelect" :job="item"/>
                </template>
            </ItemsGrid>
        </div>
        <ModalSidebar name="JobDetailsModal" :openLink="{name: 'jobs.details', params: {slug: selected_project?.slug}}">
            <JobDetails :item="selected_project" :modal="true"/>
        </ModalSidebar>
    </div>
</template>

<script>

import { metaMixin } from '~/mixins/metaMixin'
import ItemsGrid from "~/components/ItemsGrid.vue";
import FormInput from "~/components/FormInput.vue";
import Btn from "~/components/Btn.vue";
import JobDetails from "./JobDetails.vue";
import SearchIcon from "~/assets/icons/search_black.svg?inline";
import {searchMixin} from "@/mixins/searchMixin";
import JobCard from "./JobCard.vue";
import ModalSidebar from "@/components/ModalSidebar.vue";
import {mapState} from "vuex";
import SearchInput from "@/components/SearchInput.vue";

export default {
    name: 'JobsList',
    components: {SearchInput, ModalSidebar, JobCard, JobDetails, Btn, FormInput, ItemsGrid, SearchIcon},
    mixins: [metaMixin, searchMixin],
    data() {
        return {
            loaded: false,
            selected_project: null,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    async fetch () {

        // this.filters.sortorder = this.itemsSort ? this.itemsSort.value : null
        // this.filters.sel_params = []
        //
        // if (this.$route.query) {
        //     if (this.$route.query.filters) {
        //         this.filters.sel_params = JSON.parse(this.$route.query.filters)
        //     }
        //     if (this.$route.query.price) {
        //         this.filters.sel_params_price = JSON.parse(this.$route.query.price)
        //     }
        //     if (this.$route.query.page) {
        //         this.filters.page = JSON.parse(this.$route.query.page)
        //     }
        //     if (this.$route.query.sort) {
        //         let sort = this.$store.state.itemsSortOptions.find(o => o.value === this.$route.query.sort)
        //         if (sort) {
        //             this.setSort(sort)
        //         }
        //     }
        // }
        await this.getData()
    },
    mounted () {
        if (this.user.type !== 'freelancer' || this.user.status !== 1) {
            this.$toast.error('You need to login using a approved Freelancer account to access the jobs list.');
            this.$router.push({name: 'auth.login'});
            return;
        }
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch()
        }
    },
    beforeDestroy() {
        // if (this.getDataRequest) {
        //     this.getDataRequest.abort();
        // }
    },
    activated () {
        // this.sendToAnalytics()
    },
    methods: {
        getFilters () {
            this.loading.filters = true;
            return this.$axios.post(`/jobs/filters`, {
                filters: this.filters.filters,
            })
                .then(async (res) => {
                    this.filters = res.data
                    this.loading.filters = false;
                    this.loaded = true;
                    await this.getData()
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.filters = false;
                });
        },
        getData () {
            if (this.getDataRequest && this.getDataRequest.signal) {
                if (!this.getDataRequest.signal.aborted) {
                    this.getDataRequest.abort();
                }
            }

            this.getDataRequest = new AbortController();

            this.loading.data = true;
            return this.$axios.post('/jobs/search', this.filters,
                {
                    signal: this.getDataRequest.signal
                })
                .then((res) => {
                    this.data = res.data
                    this.available_filters = res.filters
                    this.$store.commit('setSortOptions', res.sorting_options)
                    // this.$store.commit('setSort', res.sorting_option)
                    this.loading.data = false;
                    this.loading.search = false;
                    // this.getDataRequest = null
                    // this.sendToAnalytics()
                })
                .catch((error) => {
                    console.error({error});
                    this.loading.data = false;
                    this.loading.search = false;
                    // this.getDataRequest = null
                });
        },
        onSelect(project) {
            this.selected_project = project;
            this.$modal.show('JobDetailsModal')
        },
    }
}
</script>
