import Vue from 'vue'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(timezone)

export default () => {
    Vue.filter('moment', (value, format) => value ? dayjs(value).format(format) : '')
    Vue.filter('momentTime', (value, format) => value ? dayjs(value, 'H:mm:ss').format(format) : '')
    Vue.filter('momentAgo', (value) => value ? dayjs(value).fromNow() : '')
    Vue.filter('percentage', (value) => `${parseFloat((value * 100).toFixed(1))}%`)

    Vue.filter('toPrice', (value, digits = 2, currency) => {
        if (typeof currency === 'undefined') {
            currency = '$'
        }
        if (typeof value === 'undefined' || value === null) {
            return '-'
        }
        if (!(`${value}`).includes('e')) {
            value = +(`${Math.round(`${value}e+${2}`)}e-${2}`)
        } else {
            const arr = (`${value}`).split('e')
            let sig = ''
            if (+arr[1] + 2 > 0) {
                sig = '+'
        }
            value = +(`${Math.round(`${+arr[0]}e${sig}${+arr[1] + 2}`)}e-${2}`)
        }
        value = parseFloat(value).toFixed(digits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return value < 0 ? `-${currency}${value*-1}` : `${currency}${value}`
    })

    Vue.filter('timeFormat', (value, format = 'HH:mm') => {
        if (!value) {
            return '-'
        }
        return dayjs(value, 'HH:mm').format(format)
    })

    Vue.filter('onlineAtFormat', (value) => {
        if (!value) {
            return '-'
        }

        let date = dayjs(value);
        const diff = date.diff(dayjs(), 'hours')
        // console.log({diff})
        if(diff < 24) {
            return 'Today, ' + date.format('HH:mm')
        }

        return date.format('MMM DD, YYYY')
    })

    Vue.filter('toDuration', (value, format = 'H[h]: mm[min]') => {
        return dayjs.duration(value, 'minutes').format(format);
    })

    Vue.filter('truncate', function (value, length) {
        if (!value && value.length < length) {
            return value
        }

        return value.substring(0, length) + '...'
    })

    Vue.filter('ucfirst', function (value) {
        if (!value) {
            return value
        }
        return value.charAt(0).toUpperCase() + value.slice(1)
    })

    Vue.filter('striphtml', function (value) {
        let div = document.createElement('div')
        div.innerHTML = value
        return div.textContent || div.innerText || ''
    })

    Vue.filter('mapObject', function (value, field) {
        if (!value || !value.length) {
            return '-'
        }
        field = field || 'name'
        return value.map(function (item) {
            return item[field]
        }).join(', ')
    })

    Vue.filter('field_length', function (value, limit) {
        let length
        let class_name = ''
        if (!value) {
            length = 0
        } else {
            length = value.length
        }

        if (length > limit) {
            class_name = 'text-danger'
        }

        return '<span class="' + class_name + '">' + length + '/' + limit + ' limit</span>'
    })

    Vue.filter('beautify', (value) => {
        if (!value) {
            return value
        }
        value = value.replace(/[_-]/g, ' ')
        return value.charAt(0).toUpperCase() + value.slice(1)
    })

    Vue.filter('pad', (value, size) => {
        value = value.toString()
        while (value.length < size) value = '0' + value
        return value
    })

    Vue.filter('addHttp', (value) => {
        if (value.indexOf('http') > -1) {
            return value
        }
        return `https://${value}`
    })

    Vue.filter('avg', (values, field, decimals = 0) => {
        if (!values || !values.length) {
            return 0;
        }
        let value = meanBy(values, field)
        return value.toFixed(decimals)
    })

    Vue.filter('humanFileSize', (bytes, si = true, dp = 1) => {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        return bytes.toFixed(dp) + ' ' + units[u];
    })

    Vue.filter('stripTags', (html) => {
        return html.replace(/<[^>]*>?/gm, '');
    })
};
